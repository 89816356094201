import React from 'react';
import { Drawer, Select } from 'antd';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import { useParams } from 'react-router-dom';
import { useFirestoreRealtimeBatched } from 'packages/utils';
import { collection, query, where } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import TimelineEvent from 'packages/events/components/TimelineEvent';
import useGetParams from 'packages/utils/hooks/useGetParams';
import PropTypes from 'prop-types';
import Title from 'components/BoxContainer/components/Title';
import { KeyValueWidget } from 'components/KeyValueWidget';
import EventName from 'components/TriggersList/component/EventName';
import IntlMessages from 'util/IntlMessages';
import styles from './styles.module.less';

function TimelineEventsDrawer({ isVisible = false, onCancel = null }) {
  const [selectedEventTypes, setSelectedEventTypes] = React.useState([]);
  const { orgId } = useParams();
  const q = useGetParams();

  const divId = q.get('divId') || null;
  const triggerId = q.get('triggerId') || null;
  const scheduleId = q.get('scheduleId') || null;
  const gte = q.get('gte') || null;
  const lte = q.get('lte')?.replace('/events', '') || null;

  const gteDate = gte ? new Date(gte * 1000) : null;
  gteDate?.setHours(0, 0, 0, 0);
  const lteDate = lte ? new Date(lte * 1000) : null;
  const showFilter = !triggerId || triggerId === 'null'; // Show filter only if triggerId is not set

  const conditions = [];
  if (triggerId && triggerId !== 'null') {
    conditions.push(where('triggerId', '==', triggerId));
  }
  if (scheduleId !== 'null' && scheduleId !== 'undefined') {
    conditions.push(where('execution.schedule.id', '==', scheduleId));
  }

  conditions.push(where('createdAtDevice', '>=', gteDate));
  conditions.push(where('createdAtDevice', '<=', lteDate));

  const bodyRef = [
    query(collection(db, 'organizations', orgId, 'divisions', divId, 'events'), ...conditions),
  ];

  const { data, loading, error } = useFirestoreRealtimeBatched(bodyRef, [orgId, divId, triggerId]);
  const forms = data
    .map(d => d.eventType)
    .filter((v, i, a) => a.findIndex(t => t.id === v.id) === i);

  const mainLoading = loading;
  const scheduleName = data?.[0]?.execution?.schedule?.name;
  const selectOptions = forms?.map(form => ({
    label: <EventName eventIcon={form.icon} eventColor={form.color} eventName={form.name} />,
    value: form?.id,
    searchText: form?.name,
  }));

  const dataFiltered = data.filter(event => {
    if (selectedEventTypes.length === 0) {
      return true;
    }
    return selectedEventTypes.includes(event?.eventType?.id);
  });

  return (
    <Drawer footer={null} closable={false} open={isVisible} onClose={onCancel}>
      <BoxContainer content shadow fixed>
        <FilterContainer
          content={
            showFilter ? (
              <KeyValueWidget
                label={<Title.Filter value={<IntlMessages id="general.form" />} />}
                value={
                  <Select
                    allowClear
                    className={styles.selectForm}
                    mode="multiple"
                    onChange={setSelectedEventTypes}
                    value={selectedEventTypes}
                    maxLength={10}
                    optionFilterProp="searchText"
                    optionLabelProp="label"
                    options={selectOptions}
                    maxTagCount={5}
                  />
                }
              />
            ) : null
          }
          title={<Title.Header value={scheduleName} />}
          goBack={onCancel}
        />
      </BoxContainer>
      <BoxContainer loading={mainLoading} error={error} empty={dataFiltered?.length === 0} content>
        {dataFiltered.map(event => (
          <TimelineEvent event={event} key={event.id} />
        ))}
      </BoxContainer>
    </Drawer>
  );
}

TimelineEventsDrawer.propTypes = {
  isVisible: PropTypes.bool,
  onCancel: PropTypes.func,
};

export default TimelineEventsDrawer;
