import React from 'react';

import { Buffer } from 'buffer';
import { Button, Modal } from 'antd';
import { useIntl } from 'react-intl';
import { QRCodeSVG } from 'qrcode.react';

import { KeyValueWidget } from 'components/KeyValueWidget';
import AvatarLocation from './AvatarLocation';

const Location = ({ location, type = 'primary' }) => {
  const intl = useIntl();
  const qrModal = content => {
    Modal.info({
      okText: intl.formatMessage({ id: 'button.ok' }),
      maskClosable: true,
      icon: null,
      content: (
        <div>
          <QRCodeSVG
            id="qrcode"
            value={content}
            size="100%"
            onClick={downloadQR}
            style={{ cursor: 'pointer' }}
          />
          <Button onClick={downloadQR} type="primary">
            {intl.formatMessage({ id: 'button.download' })}
          </Button>
        </div>
      ),
    });
  };

  const downloadQR = () => {
    const svg = document.getElementById('qrcode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');

      const downloadLink = document.createElement('a');
      downloadLink.download = 'qrcode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };

    const base64Str = Buffer.from(svgData, 'utf8').toString('base64');
    img.src = `data:image/svg+xml;base64,${base64Str}`;
  };

  const handleAction = {
    QR: content => qrModal(content),
    NFC: () => null,
    GPS: () => null,
  };
  return (
    <KeyValueWidget
      value={
        <div className="gx-flex-row gx-w-100 gx-flex-nowrap">
          <div className="gx-mr-2">
            <AvatarLocation
              locationType={location.type}
              type={type}
              onClick={() => handleAction[location.type](location.token)}
            />
          </div>
          <div className="gx-align-self-center">
            <span className="gx-font-weight-medium gx-guarnic-body">{location?.type}</span>
            {' | '}
            <span className="gx-guarnic-body">{location?.name}</span>
          </div>
        </div>
      }
    />
  );
};

export { Location };
