import React from 'react';

import { Dropdown } from 'antd';

import IntlMessages from 'util/IntlMessages';
import { ResponsiveTable } from 'components/Table';
import BoxContainer from 'components/BoxContainer';
import StatusBadge from 'components/Widget/StatusBadge';
import MemberAvatar from 'components/MemberList/MemberAvatar';

import styles from './styles.module.less';
import classNames from 'classnames';
import { useIsMobile } from 'packages/utils';
import MemberUser from 'components/MemberList/MemberUser';

export default function SelectGroupsTable({ data = [], loading, onAdd }) {
  const { isMobile } = useIsMobile();

  const COLUMNS = [
    {
      title: <IntlMessages id="groups.select.table.groups" />,
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: '20%',
    },
    {
      title: <IntlMessages id="groups.select.table.users" />,
      dataIndex: 'users',
      key: 'users',
      align: 'left',
      render: values => {
        if (isMobile && values.users.length > 1) {
          return (
            <div className="gx-d-block gx-w-100 gx-pointer">
              <Dropdown
                menu={{
                  items: values?.users?.map(usr => ({
                    label: `${usr.firstName} ${usr.lastName}`,
                    key: usr.id,
                  })),
                }}
                trigger={['click']}
              >
                <StatusBadge.Count count={`+${values.users.length}`} />
              </Dropdown>
            </div>
          );
        } else if (isMobile && values.users.length === 1) {
          return (
            <MemberUser
              user={values.users[0]}
              displayName={`${values.users[0].firstName} ${values.users[0].lastName}`}
            />
          );
        } else {
          return (
            <div className={styles.displayGroupUsers}>
              {values.users.map(usr => (
                <MemberUser
                  key={usr.id}
                  user={usr}
                  displayName={`${usr.firstName} ${usr.lastName}`}
                />
              ))}
            </div>
          );
        }
      },
    },
    {
      title: <IntlMessages id="button.add" />,
      dataIndex: 'add',
      key: 'add',
      render: values => (
        <div
          onClick={() => onAdd(values)}
          className="gx-d-flex gx-justify-content-sm-center gx-pointer"
        >
          <span className={classNames(styles.addButton, 'material-symbols-outlined')}>
            add_circle
          </span>
        </div>
      ),
      width: '10%',
    },
  ];

  return (
    <BoxContainer empty={data.length === 0}>
      <ResponsiveTable
        className={styles.selectUsersTable}
        loading={loading}
        dataSource={data}
        columns={COLUMNS}
      />
    </BoxContainer>
  );
}
