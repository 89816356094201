/* eslint-disable no-restricted-syntax */
import { useCallback, useEffect, useRef, useState } from 'react';
import { off, onValue } from 'firebase/database';

const useGetDataFromDatabase = queryRefList => {
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const unsubscribesRef = useRef([]);
  const loadingByBatch = useRef({});

  const unsubscribe = useCallback(() => {
    unsubscribesRef.current.forEach(q => {
      off(q);
    });
    unsubscribesRef.current = [];
  }, []);

  const listener = useCallback(
    q =>
      new Promise((resolve, reject) => {
        loadingByBatch.current[q] = true;
        try {
          onValue(
            q,
            snapshot => {
              const newData = snapshot.toJSON();
              const docID = snapshot.key;
              if (snapshot.exists()) {
                const oldData = data[docID];
                // NOTE: Check if something changed in order to set new values, it's avoid a loop infinite
                if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
                  setData(prevData => ({
                    ...prevData,
                    [docID]: newData,
                  }));
                }
              }
              loadingByBatch.current[q] = false;
              resolve();
            },
            e => {
              setError(e);
              return new Error(e);
            },
          );
        } catch (err) {
          reject(err);
        }
      }),
    [data],
  );

  const fetch = useCallback(async () => {
    for (const newQuery of queryRefList) {
      // eslint-disable-next-line no-await-in-loop
      await listener(newQuery);
      unsubscribesRef.current.push(newQuery);
    }
  }, [listener, queryRefList]);

  useEffect(
    () =>
      // Cleanup function: unsubscribe on unmount
      unsubscribe,
    [unsubscribe],
  );

  useEffect(() => {
    if (!queryRefList?.length || error) {
      loadingByBatch.current = {};
      return;
    }
    fetch();
  }, [queryRefList, fetch, error]);

  const dataParsed = Object.keys(data).map(item => {
    const value = data[item];

    return {
      id: item,
      ...value,
    };
  });
  return {
    data: dataParsed,
    loading: error ? false : Object.values(loadingByBatch.current).some(i => i),
    error,
  };
};

export default useGetDataFromDatabase;
