import moment from 'moment';
import { get } from 'packages/utils/storage';
import { EXECUTION_STATUS } from 'constants/commons';
import { getTriggersByExecution } from 'util/firebase-operations/executions/triggers/get';
import { getUserTimeZoneDate } from '..';
import { getCompletitionData } from '../triggers';

export const getActiveAndNextExecutions = executions => {
  const activeExecutions = [];
  const nextExecutions = [];
  const todayDate = moment();

  executions.forEach(execution => {
    const executionDate = moment(execution?.startAt.toDate());
    const endDate = moment(execution?.endAt.toDate());

    if (executionDate.isBefore(todayDate) && endDate.isAfter(todayDate)) {
      activeExecutions.push(execution);
    } else if (executionDate.isAfter(todayDate)) {
      nextExecutions.push(execution);
    }
  });

  return {
    activeExecutions,
    nextExecutions,
  };
};

export const compareExecutions = executions => {
  const pendings = [];
  const expires = [];
  const actives = [];

  const now = new Date();

  executions.forEach(e => {
    const isPending = now < e?.startAt?.toDate();
    const isExpired = now > e?.endAt?.toDate();

    if (isPending) {
      pendings.push(e);
      return;
    }
    if (isExpired) {
      expires.push(e);
      return;
    }
    // Else is Active
    actives.push(e);
  });

  actives.sort((a, b) => a.endAt.localeCompare(b.endAt));
  pendings.sort((a, b) => a.endAt.localeCompare(b.endAt));
  expires.sort((a, b) => a.endAt.localeCompare(b.endAt));

  return {
    active: actives,
    expired: expires,
    pending: pendings,
  };
};

export const calculateNewStatus = (data, triggersUpdated) =>
  data.map(record => {
    const { startTz, hasExtraTime, endTime, endTimeWithExtra, triggers } = record;
    let status = '';
    const now = Date.now();
    const outputTrigger = triggersUpdated[record.id] || triggers;
    const triggerCompletition = getCompletitionData(outputTrigger);
    const isCompleteAll = triggerCompletition?.finishedPercentage === 100;
    const isIncomplete =
      triggerCompletition?.finishedPercentage > 0 && triggerCompletition?.finishedPercentage < 100;

    if (now >= startTz.getTime() && now <= endTime) {
      // in progress
      status = isCompleteAll ? EXECUTION_STATUS.completed : EXECUTION_STATUS.active;
    } else if (now > endTime) {
      // With delay - with extra time
      if (hasExtraTime && now <= endTimeWithExtra) {
        if (isCompleteAll) {
          status = EXECUTION_STATUS.completed;
        } else {
          status = EXECUTION_STATUS.delayed;
        }
      } else if (isCompleteAll) {
        status = EXECUTION_STATUS.completed;
      } else if (isIncomplete) {
        // incomplete
        status = EXECUTION_STATUS.incomplete;
      } else {
        status = EXECUTION_STATUS.expired;
      }
    } else {
      // inactive
      status = EXECUTION_STATUS.inactive;
    }

    return {
      ...record,
      status,
      triggers: outputTrigger,
      triggerCompletition,
      triggersProgress: triggerCompletition?.finishedPercentage || 0,
    };
  });

export const executionFactory = async (data = []) => {
  if (!data) return [];
  const profileTimeZone = get('timeZone') || 'America/Argentina/Buenos_Aires';
  return Promise.all(
    data?.map(async record => {
      const {
        organization: { id: orgId } = {},
        division: { id: divId },
        id: execId,
        startAt,
        endAt,
        schedule: { schedule, expiredAllowedTime, isExpiredAllowed, triggers: pureTriggers },
      } = record;

      const startTz = getUserTimeZoneDate(
        startAt.toMillis(),
        schedule.timeZone,
        profileTimeZone,
      ).jsDate;
      const endTz = getUserTimeZoneDate(
        endAt.toMillis(),
        schedule.timeZone,
        profileTimeZone,
      ).jsDate;
      const hasExtraTime = isExpiredAllowed || false;
      const endTime = endTz.getTime();
      const timeLeft = Math.max(0, Math.floor((endTime - Date.now()) / (1000 * 60)));

      const endExtraTime = endTime + expiredAllowedTime * 60 * 1000;
      const endTimeWithExtra = hasExtraTime ? endExtraTime : endTime;
      const triggersResponse = await getTriggersByExecution(orgId, divId, execId);
      const triggers = triggersResponse?.length > 0 ? triggersResponse : pureTriggers; // There is some delay when an order is created so we take the pure triggers from execution
      const triggerCompletition = getCompletitionData(triggers);
      const isCompleteAll = triggerCompletition?.finishedPercentage === 100;
      const isIncomplete =
        triggerCompletition?.finishedPercentage > 0 &&
        triggerCompletition?.finishedPercentage < 100;

      let status = '';
      const now = Date.now();

      if (now >= startTz.getTime() && now <= endTime) {
        // in progress
        status = isCompleteAll ? EXECUTION_STATUS.completed : EXECUTION_STATUS.active;
      } else if (now > endTime) {
        // With delay - with extra time
        if (hasExtraTime && now <= endTimeWithExtra) {
          if (isCompleteAll) {
            status = EXECUTION_STATUS.completed;
          } else {
            status = EXECUTION_STATUS.delayed;
          }
        } else if (isCompleteAll) {
          status = EXECUTION_STATUS.completed;
        } else if (isIncomplete) {
          // incomplete
          status = EXECUTION_STATUS.incomplete;
        } else {
          status = EXECUTION_STATUS.expired;
        }
      } else {
        // inactive
        status = EXECUTION_STATUS.inactive;
      }

      return {
        ...record,
        divId,
        startTz,
        endTz,
        hasExtraTime,
        endExtraTime,
        timeLeft,
        status,
        triggers,
        triggerCompletition,
        triggersProgress: triggerCompletition?.finishedPercentage || 0,
        endTimeWithExtra,
        endTime,
      };
    }),
  );
};
