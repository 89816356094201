import React from 'react';
import styles from './styles.module.less';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin, Result, Button, Skeleton, Empty } from 'antd';
import IntlMessages from 'util/IntlMessages';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ChildrenSchema } from 'packages/utils';
import BottonSpacing from 'components/BottonSpacing';

/**
 *
 * @param {content} // going to center all the content and set a specific width
 */

const BoxContainer = ({
  children,
  content,
  shadow,
  fixed,
  loading,
  error,
  skeleton,
  empty,
  fullScreen,
  className,
  bottomSpacing = null,
  ...props
}) => {
  const history = useHistory();
  const initStyles = content ? styles.content : styles.wrapper;
  const fixedStyles = fixed && styles.fixed;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const hasError = Boolean(error);
  const errorName = `${error?.name || ''}${error?.message || ''}${error?.code || ''}${
    error?.status || ''
  }${error?.error || ''}`;

  return (
    <div
      className={classNames(
        styles.main,
        shadow && styles.shadow,
        fixedStyles,
        fullScreen && styles.fullScreen,
        className,
      )}
      {...props}
    >
      <Spin spinning={loading}>
        <div className={initStyles}>
          {!loading && empty && !hasError ? (
            <Empty description={<IntlMessages id="general.empty.state" />} />
          ) : !loading && hasError ? (
            <Result
              className={styles.errorContainer}
              status="500"
              title={<IntlMessages id="notification.somethingWentWrong" />}
              subTitle={errorName}
              extra={
                <Button onClick={() => history.push('/')} type="primary">
                  <IntlMessages id="button.goBack" />
                </Button>
              }
            />
          ) : loading && skeleton ? (
            <Skeleton />
          ) : (
            children
          )}
        </div>
      </Spin>
      {bottomSpacing && bottomSpacing?.active && (
        <div className={styles.bottonSpacingContainer}>
          <BottonSpacing align={bottomSpacing?.align}>{bottomSpacing?.content}</BottonSpacing>
        </div>
      )}
    </div>
  );
};

BoxContainer.defaultProps = {
  content: false,
  shadow: false,
  fixed: false,
  loading: false,
  error: false,
  skeleton: false,
  empty: false,
  fullScreen: false,
  className: '',
  bottomSpacing: null,
};
BoxContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
  content: PropTypes.bool,
  shadow: PropTypes.bool,
  fixed: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.any,
  skeleton: PropTypes.bool,
  empty: PropTypes.bool,
  fullScreen: PropTypes.bool,
  className: PropTypes.string,
  bottomSpacing: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
    align: PropTypes.string,
    active: PropTypes.bool,
  }),
};
export default BoxContainer;
