import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMap } from 'react-leaflet';

const FlyToMap = ({ position }) => {
  const map = useMap();

  useEffect(() => {
    if (position) {
      map.flyTo(position);
    }
  }, [position]);

  return null;
};

FlyToMap.defaultProps = {
  position: null,
};

FlyToMap.propTypes = {
  position: PropTypes.array,
};

export default FlyToMap;
