import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Empty, Modal, Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import {
  ALLOWED_ROLES,
  getDivsWithAccess,
  useFirestoreRealtimeBatchedPagination,
  useToggle,
} from 'packages/utils';
import { db } from 'firebase/firebase';
import { collection, orderBy, query, Timestamp, where } from 'firebase/firestore';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import moment from 'moment';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { InfoCard } from 'components/InfoCard';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { getScheduleStatus } from 'packages/utils/functions/schedule';
import Members from 'components/MemberList/Members';
import { EventTypeList } from 'packages/schedules/components/Event';
import { ScheduleInfo } from 'components/schedule';
import ScheduleFilter from 'packages/schedules/components/ScheduleFilter';
import { SCHEDULE_TYPES } from 'constants/commons';
import { INIT_FILTERS, PAGE_SIZE, PATHS, getRouteToEditSchedule } from '../../constants';
import { scheduleDelete } from '../../redux/actions';

const SchedulesList = () => {
  const history = useHistory();
  const scheduleListRef = useRef(null);

  const orgId = useSelector(state => state.organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const loadedSchedules = useSelector(({ schedules }) => schedules.schedule.save.loaded);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const divisions = useSelector(({ user }) => user.profile.data.organizations[orgId]?.divisions);
  const getDivisionById = divId => divisions[divId];
  const intl = useIntl();
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(INIT_FILTERS);
  const [search, setSearch] = useToggle(false);
  const [scheduleList, setScheduleList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);
  const currentDate = moment().toDate();
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  const isFilterefByRange = filters.dates.some(
    element => element !== undefined || element !== null,
  );

  const queryByDivId = {};
  allowedSelectedDivs.forEach(divId => {
    queryByDivId[divId] = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
      where('type', '==', SCHEDULE_TYPES.schedule),
      orderBy('createdAt', 'desc'),
    );
  });

  if (filters.active === '0') {
    Object.keys(queryByDivId).forEach(divId => {
      queryByDivId[divId] = query(queryByDivId[divId], where('schedule.endAt', '>=', currentDate));
    });
  } else {
    Object.keys(queryByDivId).forEach(divId => {
      queryByDivId[divId] = query(queryByDivId[divId], where('schedule.endAt', '<=', currentDate));
    });
  }

  if (isFilterefByRange) {
    Object.keys(queryByDivId).forEach(divId => {
      queryByDivId[divId] = query(
        queryByDivId[divId],
        where('schedule.startAt', '>=', Timestamp.fromMillis(filters.dates[0].valueOf())),
        where('schedule.endAt', '<=', Timestamp.fromMillis(filters.dates[1].valueOf())),
      );
    });
  }

  if (filters.user) {
    Object.keys(queryByDivId).forEach(divId => {
      queryByDivId[divId] = query(queryByDivId[divId], where('uid', '==', filters.user));
    });
  }

  const { data, loading, next, gotNewData, error } = useFirestoreRealtimeBatchedPagination(
    Object.values(queryByDivId),
    [selectedDivsIds, loadedSchedules, search],
    PAGE_SIZE,
  );
  const handleCreate = () => history.push(PATHS.CREATE_SCHEDULE);
  const onEdit = (divId, id) => history.push(getRouteToEditSchedule(divId, id));
  const onDelete = (divId, id, name) => {
    Modal.confirm({
      cancelText: intl.formatMessage({ id: 'button.no' }),
      content: intl.formatMessage({ id: 'schedules.list.delete.content' }),
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({ id: 'button.yes' }),
      okType: 'danger',
      onOk() {
        dispatch(scheduleDelete(orgId, divId, id));
      },
      title: intl.formatMessage({ id: 'schedules.list.delete.title' }, { name }),
    });
  };
  const onClone = (divId, id) =>
    history.push(getRouteToEditSchedule(divId, id), {
      isToClone: true,
    });

  const start = (currentPage - 1) * PAGE_SIZE;
  const end = start + PAGE_SIZE;
  const filterDataByName = useCallback(
    d =>
      d
        .filter(item => {
          const scheduleName = (item?.name || '').toLowerCase();
          return scheduleName.includes(filters.name.toLowerCase());
        })
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
    [filters.name],
  );

  const handlePageChange = page => {
    setCurrentPage(page);
    if (gotNewData && !loading) {
      next();
    }
    scheduleListRef.current.scrollIntoView();
  };

  const onClear = () => {
    setFilters(INIT_FILTERS);
    setSearch();
  };

  const statusSchedule = status =>
    status ? (
      <span className="gx-text-green">
        <IntlMessages id="schedules.list.status.active.label" />
      </span>
    ) : (
      <span className="gx-text-grey">
        <IntlMessages id="schedules.list.status.inactive.label" />
      </span>
    );

  useEffect(() => {
    if (JSON.stringify(filterDataByName(data)) !== JSON.stringify(scheduleList)) {
      setScheduleList(filterDataByName(data));
    }
  }, [data, filterDataByName, scheduleList]);

  const output = scheduleList.slice(start, end);
  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          showHide
          actionButtons={[
            {
              label: <IntlMessages id="general.search" />,
              action: () => setSearch(),
              type: 'secondary',
            },
            {
              label: <IntlMessages id="button.clear" />,
              action: () => onClear(),
              type: 'secondary',
            },
            {
              label: <IntlMessages id="button.create" />,
              action: handleCreate,
              type: 'primary',
              allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.CREATE,
            },
          ]}
          title={<Title.Header value={<IntlMessages id="schedule.title" />} />}
          content={<ScheduleFilter handleFilters={setFilters} filters={filters} />}
        />
      </BoxContainer>
      <BoxContainer content error={!!error} loading={loading}>
        {output.map(({ id, name, schedule, divId, duration, members, triggers, jobs }, idx) => (
          <React.Fragment key={id}>
            <div ref={idx === 0 ? scheduleListRef : null}>
              <InfoCard
                key={id}
                title={<InfoCard.Title value={<IntlMessages id="schedules.list.title" />} />}
                subTitle={<InfoCard.SubTitle value={name} />}
                actionButtons={[
                  {
                    iconButton: 'edit',
                    shape: 'circle',
                    action: () => onEdit(divId, id),
                    allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.UPDATE,
                    divId,
                  },
                  {
                    iconButton: 'delete',
                    shape: 'circle',
                    action: () => onDelete(divId, id, name),
                    allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.DELETE,
                    divId,
                  },
                  {
                    iconButton: 'clone',
                    shape: 'circle',
                    action: () => onClone(divId, id),
                    allowedRole: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.UPDATE,
                    divId,
                  },
                ]}
              >
                <ScheduleInfo
                  key={id}
                  startDate={schedule?.startAt?.toDate()}
                  endDate={schedule?.endAt.toDate()}
                  divisionName={getDivisionById(divId)?.name}
                  duration={duration}
                  status={statusSchedule(
                    getScheduleStatus(schedule?.startAt.toDate(), schedule?.endAt.toDate()),
                  )}
                  jobs={jobs}
                  schedule={schedule}
                  layout={{ xs: 12, xl: 6, xxl: 6 }}
                />
                <div className="gx-pt-4">
                  <Members keyProp={id} users={members?.users} groups={members?.groups} />
                </div>
                <div className="gx-pt-4">
                  <div className="gx-w-100 gx-pb-3">
                    <KeyValueWidget
                      label={
                        <span className="gx-guarnic-headline-2">
                          <IntlMessages id="schedules.list.eventTypes.label" />
                        </span>
                      }
                    />
                  </div>
                  <EventTypeList
                    locationType="primary"
                    triggers={triggers}
                    layout={{ xs: 24, xl: 12, xxl: 12 }}
                  />
                </div>
              </InfoCard>
            </div>
          </React.Fragment>
        ))}
      </BoxContainer>
      {!error && !loading && output.length === 0 && (
        <Empty description={intl.formatMessage({ id: 'general.empty.state' })} />
      )}
      <div className="gx-flex-row gx-justify-content-center gx-mt-4">
        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          pageSize={PAGE_SIZE}
          total={scheduleList.length}
        />
      </div>
    </BoxContainer>
  );
};

export default SchedulesList;
