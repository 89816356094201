import React from 'react';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import { Button } from 'antd';

function MoreLessButton({ flag = false, total = 0, ...props }) {
  return (
    <Button {...props} className="gx-mb-0" type="link">
      {flag ? (
        <IntlMessages id="button.seeLess" />
      ) : (
        <>
          {total > 0 && (
            <>
              <IntlMessages id="button.seeMore" />
              <span className="gx-pl-1">{`(${total})`}</span>
            </>
          )}
        </>
      )}
    </Button>
  );
}

MoreLessButton.propTypes = {
  flag: PropTypes.bool.isRequired,
  total: PropTypes.number.isRequired,
};

export default MoreLessButton;
