import React from 'react';
import styles from './styles.module.less';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

const StatusBadge = ({ status, type, ...props }) => {
  const intl = useIntl();

  const options = {
    users: {
      colors: {
        AVAILABLE: styles.userActive,
        UNAVAILABLE: styles.userInactive,
      },
      labels: {
        AVAILABLE: intl.formatMessage({ id: 'user.status.available' }),
        UNAVAILABLE: intl.formatMessage({ id: 'user.status.unavailable' }),
        UNKNOWN: '-',
      },
    },
  };

  return (
    <div {...props} className="gx-d-flex gx-justify-content-center">
      <div
        className={classNames(
          'gx-d-flex gx-justify-content-center',
          options[type]?.colors[status?.toUpperCase()],
        )}
      >
        {options[type]?.labels[status?.toUpperCase()]}
      </div>
    </div>
  );
};

StatusBadge.Count = ({ count, ...props }) => {
  return (
    <div {...props} className="gx-d-flex gx-justify-content-center">
      <div className={classNames('gx-d-flex gx-justify-content-center', styles.statusCount)}>
        {count}
      </div>
    </div>
  );
};

export default StatusBadge;
