import React, { useState } from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Avatar, notification, Pagination, Spin } from 'antd';

import { AimOutlined } from '@ant-design/icons';
import { getInitialsName } from 'packages/utils';
import { InfoCardList } from 'components/InfoCard';
import BoxContainer from 'components/BoxContainer';
import { USER_PER_LIST } from 'packages/reports/constants';

import SimpleColorPicker from 'components/ColorPicker/SimpleColorPicker';
import styles from './styles.module.less';

const UserList = ({ users, onSetFilters, loading, onChangeColor }) => {
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);

  const onActiveInMap = userId => {
    // NOTE: check if has position, if it doesn't have it, we don't include it
    const user = users.find(rec => rec.id === userId);
    const hasPosition = !!user?.geoData;

    if (!hasPosition) {
      return notification.info({
        message: user?.displayName,
        description: intl.formatMessage({ id: 'reports.userPositionHistory.map.without.position' }),
        placement: 'topRight',
      });
    }

    return onSetFilters(prev => {
      const isActive = prev.includes(userId);
      const updatedActive = isActive ? prev.filter(pId => pId !== userId) : [...prev, userId];

      return updatedActive;
    });
  };

  const onPagination = page => {
    setCurrentPage(page);
  };

  const handleColor = (userId, newColor) => {
    onChangeColor(prev => ({
      ...prev,
      [userId]: newColor,
    }));
  };

  const start = (currentPage - 1) * USER_PER_LIST;
  const end = start + USER_PER_LIST;
  const usersParsed = users?.slice(start, end);

  return (
    <div className="gx-flex-column gx-justify-content-between gx-h-100">
      <Spin spinning={loading}>
        <BoxContainer empty={usersParsed.length === 0}>
          {usersParsed.map(usr => (
            <InfoCardList
              className={styles.customUserList}
              key={usr.id}
              extraRightClassName={styles.extraRightStyle}
              extraRightContent={
                <SimpleColorPicker
                  hexColor
                  onChange={newRgB => handleColor(usr.id, newRgB)}
                  color={usr.color}
                  key={usr.id}
                  className={styles.customSimpleColor}
                />
              }
              actionButtons={[
                {
                  iconButton: 'custom',
                  customIcon: (
                    <AimOutlined
                      style={usr.status ? { color: usr.color } : { color: '#666' }}
                      className={classNames(styles.commonSwitch)}
                    />
                  ),
                  action: () => onActiveInMap(usr.id),
                  type: 'link',
                  disabled: loading,
                  className: styles.customActionButton,
                },
              ]}
              title={usr.displayName}
              avatar={
                <>
                  {usr.avatar ? (
                    <Avatar size={24} src={usr.avatar} />
                  ) : (
                    <Avatar size={24} src={usr.avatar}>
                      {getInitialsName(usr?.displayName)}
                    </Avatar>
                  )}
                </>
              }
            />
          ))}
        </BoxContainer>
      </Spin>
      <div className="gx-flex-row gx-justify-content-center">
        <Pagination
          pageSize={USER_PER_LIST}
          onChange={onPagination}
          current={currentPage}
          total={users.length || 0}
        />
      </div>
    </div>
  );
};

UserList.defaultProps = {
  users: [],
};
UserList.propTypes = {
  users: PropTypes.array,
  onSetFilters: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onChangeColor: PropTypes.func.isRequired,
};

export default UserList;
