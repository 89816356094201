import { KeyValueWidget } from 'components/KeyValueWidget';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { CircleMarker, Polyline, Popup } from 'react-leaflet';
import IntlMessages from 'util/IntlMessages';

const Coordinates = props => {
  const { positions = [], color, highlightPos, circleRef } = props;
  const positionsParsed = positions?.map(rc => [rc?.location?.lat, rc?.location?.lon]);

  return (
    <Polyline pathOptions={{ color: color }} positions={positionsParsed}>
      {positions.map(position => (
        <React.Fragment key={position.id}>
          <CircleMarker
            key={position.id}
            center={[position?.location?.lat, position?.location?.lon]}
            radius={highlightPos === position.id ? 10 : 4}
            pathOptions={{ fillColor: highlightPos === position.id ? '#038fde' : color, color }}
            fillOpacity={1}
            ref={el => (circleRef.current[position.id] = el)}
          >
            <Popup>
              <KeyValueWidget
                compactValue
                oneLine
                label={<IntlMessages id="common.time" />}
                value={position.time}
              />
              <KeyValueWidget
                compactValue
                oneLine
                label={<IntlMessages id="common.accuracy" />}
                value={position?.accuracy}
              />
              <KeyValueWidget
                compactValue
                oneLine
                label={<IntlMessages id="common.speed" />}
                value={`${position?.speed ?? 0} km/h`}
              />
            </Popup>
          </CircleMarker>
        </React.Fragment>
      ))}
    </Polyline>
  );
};

Coordinates.defaultProps = {
  positon: null,
  color: '#000',
  highlightPos: null,
  circleRef: {
    current: {},
  },
};

Coordinates.propTypes = {
  position: PropTypes.array,
  color: PropTypes.string,
  highlightPos: PropTypes.string,
  circleRef: PropTypes.shape({ current: PropTypes.any }),
};

export default memo(Coordinates);
