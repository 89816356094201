import * as Sentry from '@sentry/browser';
import { auth } from 'firebase/firebase';

/**
 * Function to send errors to Sentry
 * @param {Error} error - The error object to send
 * @param {string} from - Example of additional info key
 * @param {Object} [additionalInfo] - Optional additional information
 * @param {Object} [additionalInfo.functionName]
 */
const sendErrorToSentry = (error, additionalInfo = {}) => {
  const extraInfo = {
    ...additionalInfo,
    uid: auth.currentUser?.uid,
  };
  Sentry.withScope(scope => {
    // Add additional information to the scope if it exists
    Object.keys(extraInfo).forEach(key => {
      scope.setExtra(key, extraInfo[key]);
    });

    // Capture the exception
    Sentry.captureException(error);
  });
};

export { sendErrorToSentry };
