import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { useIsMobile } from 'packages/utils';
import { Location } from 'components/Location';
import ProgressBar from 'components/ProgressBar';
import BoxContainer from 'components/BoxContainer';
import { SCHEDULE_STATUS } from 'constants/commons';
import { InfoCard, InfoCardList } from 'components/InfoCard';
import useLimitList from 'packages/utils/hooks/useLimitList';
import MoreLessButton from 'components/Buttons/MoreLessButton';
import EventName from 'components/TriggersList/component/EventName';
import { getCompletitionData } from 'packages/utils/functions/triggers';
import { eventTypeSummaryPropTypes } from 'packages/utils/proptypes/eventTypes';

import styles from './styles.module.less';

const EventTypeList = ({ data = [], onRemove, onAdd, wrapperData = false }) => {
  const {
    data: dataLimited,
    seeAll,
    totalToBeOpeneable,
    onSeeToggle,
    showButton,
  } = useLimitList({ data: data, limitForMobile: 1, limitForDesktop: 2 });

  const getActions = event => {
    const output = [];
    if (onAdd) {
      output.push({
        iconButton: 'add',
        action: () => onAdd(event),
        shape: 'circle',
        type: 'link',
        size: 'large',
      });
    }
    if (onRemove) {
      output.push({ iconButton: 'delete', action: () => onRemove(event.id), shape: 'circle' });
    }

    return output;
  };

  const records = wrapperData ? dataLimited : data;

  return (
    <BoxContainer empty={data.length === 0}>
      {records.length > 0 &&
        records.map(event => (
          <React.Fragment key={event.id}>
            <InfoCardList
              title={
                <EventName
                  eventName={event?.name}
                  eventColor={event?.color}
                  eventIcon={event?.icon}
                />
              }
              actionButtons={getActions(event)}
            />
          </React.Fragment>
        ))}

      {wrapperData && showButton && (
        <div className="gx-flex-row gx-justify-content-center gx-w-100 gx-pb-2">
          <MoreLessButton flag={seeAll} total={totalToBeOpeneable} onClick={onSeeToggle} />
        </div>
      )}
    </BoxContainer>
  );
};

const drawLocations = (locations = [], locationType = 'primary') => (
  <div className="gx-flex-xl-row gx-flex-column gx-guarnic-gap-1 gx-align-items-xl-center">
    {locations?.map(loc => (
      <div key={loc?.id}>
        <Location location={loc} type={locationType} />
      </div>
    ))}
  </div>
);

/*
The SimpleList component accepts the following props:
- data: An array of eventTypeSummaryPropTypes, representing the data to be displayed in the list.
- wrapperData: A boolean indicating whether the data should be wrapped in a container.
- infoCardType: A string specifying the type of info card to be used (e.g., 'light' or 'dark').
- limitOptions: An object specifying the limit for displaying items on mobile and desktop.
- onSeeMore: A function to be called when the "See More" button is clicked.
- onGoToTimeline: A function to be called when the timeline button is clicked.
*/
EventTypeList.SimpleList = ({
  data = [],
  wrapperData = false,
  infoCardType = 'light',
  limitOptions = { mobile: 1, desktop: 2 },
  onSeeMore = null,
  onGoToTimeline = null,
}) => {
  const { isMobile } = useIsMobile();
  const locationType = (infoCardType === 'light' && 'primary') || 'light';

  const {
    data: dataLimited,
    seeAll,
    totalToBeOpeneable,
    onSeeToggle,
    showButton,
  } = useLimitList({
    data: data,
    limitForMobile: limitOptions.mobile,
    limitForDesktop: limitOptions.desktop,
  });

  const getSingleCalculate = tr => {
    const trigger = getCompletitionData([tr]);
    const percentage = trigger?.finishedPercentage || 0;

    const status = percentage < 100 ? SCHEDULE_STATUS.active : SCHEDULE_STATUS.completed;

    return {
      percentage: trigger?.finishedPercentage || 0,
      status,
    };
  };

  const buildButtons = useCallback(
    (tr = {}) => {
      if (Object.values(tr).length === 0) return [];
      const auxButtons = [];

      if (onGoToTimeline) {
        auxButtons.push({
          iconName: 'format_list_bulleted',
          type: 'primary',
          action: () => onGoToTimeline(tr?.id),
          disabled: tr?.finishedExecutions === 0,
        });
      }

      return auxButtons;
    },
    [onGoToTimeline],
  );

  const records = wrapperData ? dataLimited : data;
  return (
    <>
      {records?.length > 0 &&
        records?.map(trigger => (
          <React.Fragment key={trigger?.id}>
            <InfoCard
              className={styles.eventTypeV1HideBorder}
              type={infoCardType}
              title={
                <div className={styles.eventTypeV1Title}>
                  <div>
                    <EventName
                      eventName={<span className="gx-guarnic-headline-2">{trigger?.name}</span>}
                      eventColor={trigger?.eventType?.color}
                      eventIcon={trigger?.eventType?.icon}
                      formName={trigger?.eventType?.name}
                    />
                  </div>
                  {!isMobile && trigger.locations?.length > 0 && (
                    <div className={styles.verticalBar} />
                  )}

                  {!isMobile && drawLocations(trigger?.locations, locationType)}
                </div>
              }
              buttonItems={buildButtons(trigger)}
              extraRightContent={
                <div className={styles.sigleProgress}>
                  <ProgressBar.Quantity
                    expected={trigger.expectedExecutions}
                    finished={trigger.finishedExecutions}
                    status={getSingleCalculate(trigger)?.status}
                  />
                </div>
              }
            />
          </React.Fragment>
        ))}
      {wrapperData && (showButton || onSeeMore) && (
        <div className="gx-flex-row gx-justify-content-center gx-w-100 gx-pb-2">
          <MoreLessButton
            flag={seeAll}
            total={totalToBeOpeneable}
            onClick={onSeeMore || onSeeToggle}
          />
        </div>
      )}
    </>
  );
};

EventTypeList.propTypes = {
  data: PropTypes.arrayOf(eventTypeSummaryPropTypes),
  onRemove: PropTypes.func,
  onAdd: PropTypes.func,
  wrapperData: PropTypes.bool,
};

export default EventTypeList;
