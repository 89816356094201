import React from 'react';
import { Avatar, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import EventTypeIcon from 'components/EventTypeIcon';

import styles from '../styles.module.less';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';

const EventName = ({ eventColor = null, eventIcon = null, eventName = '', formName = '' }) => {
  return (
    <div className={styles.triggerNameContainer}>
      {eventColor && eventIcon ? (
        <div className={styles.triggerNameIcon}>
          <EventTypeIcon color={eventColor} icon={eventIcon} />
        </div>
      ) : (
        <div className={styles.triggerNameIcon}>
          <Avatar size={32} icon={<EyeInvisibleOutlined />} />
        </div>
      )}
      <div className={styles.triggerNameTitle}>
        <span className="gx-mr-2 gx-ml-2">{eventName}</span>
        {formName && (
          <Tooltip placement="bottom" title={<IntlMessages id="general.form" />}>
            <div className="gx-d-flex gx-align-items-center gx-guarnic-gap-1">
              <span className="material-symbols-outlined gx-fs-md gx-guarnic-body-light">
                assignment
              </span>
              <span className="gx-guarnic-body-light gx-flex-nowrap">{formName}</span>
            </div>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

EventName.propTypes = {
  eventColor: PropTypes.object,
  eventIcon: PropTypes.string,
  eventName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default EventName;
