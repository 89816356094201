import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Empty } from 'antd';
import { InfoCard } from 'components/InfoCard';
import { generateKey, sortByField, useFirestoreQueryBatched } from 'packages/utils';
import { collection, query, where, orderBy } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import moment from 'moment';
import { useSelector } from 'react-redux';
import IntlMessages from 'util/IntlMessages';
import { getScheduleStatus } from 'packages/utils/functions/schedule';
import { ScheduleInfo } from 'components/schedule';
import Members from 'components/MemberList/Members';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { EventTypeList } from 'packages/schedules/components/Event';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { getActiveAndNextExecutions } from 'packages/utils/functions/executions';
import ScheduleStatus from 'components/schedule/ScheduleStatus';

const SchedulesActive = ({ isVisible, onCancel, user }) => {
  const orgId = useSelector(state => state.organizations.organization.id);
  const divisions = useSelector(
    ({ user: userState }) => userState.profile.data.organizations[orgId]?.divisions,
  );
  const getDivisionById = divId => divisions[divId];

  // Build query per divisions
  const divisionsPerUser = useMemo(() => Object.keys(user.divisions), [user.divisions]);
  const queryByDivId = divisionsPerUser.map(divId =>
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'executions'),
      where('usersWithAccess', 'array-contains', user?.id),
      where('endAt', '>', moment().subtract(1, 'day').toDate()),
      orderBy('endAt', 'desc'),
    ),
  );

  const { data, loading } = useFirestoreQueryBatched(queryByDivId, [divisionsPerUser]);

  const { activeExecutions } = getActiveAndNextExecutions(data);

  const executionsActiveSorted = sortByField(activeExecutions, 'createdAt');

  return (
    <Drawer open={isVisible} onClose={() => onCancel(false)} closable={false} footer={null}>
      <BoxContainer>
        <BoxContainer content fixed>
          <FilterContainer
            goBack={() => onCancel(false)}
            title={
              <Title.Header
                value={<IntlMessages id="activeUserDashboard.schedule.drawer.title" />}
              />
            }
          />
        </BoxContainer>
        <BoxContainer content loading={loading}>
          {executionsActiveSorted?.length > 0 ? (
            executionsActiveSorted.map(execution => {
              const { id, schedule, division } = execution;
              const sch = schedule?.schedule;
              const startAt = sch?.startAt;
              const endAt = sch?.endAt;
              const members = schedule?.members;
              return (
                <InfoCard
                  key={generateKey(id)}
                  title={<InfoCard.Title value={<IntlMessages id="schedules.list.title" />} />}
                  subTitle={<InfoCard.SubTitle value={schedule?.name} />}
                >
                  <ScheduleInfo
                    key={id}
                    startDate={startAt.toDate()}
                    endDate={endAt.toDate()}
                    divisionName={getDivisionById(division?.id)?.name}
                    duration={schedule?.duration}
                    status={
                      <ScheduleStatus.Single
                        status={getScheduleStatus(startAt.toDate(), endAt.toDate())}
                      />
                    }
                    jobs={schedule?.jobs}
                    schedule={schedule?.schedule}
                  />
                  <div className="gx-pt-4">
                    <Members keyProp={id} users={members.users} groups={members?.groups} />
                  </div>
                  <div className="gx-pt-4">
                    <div className="gx-w-100 gx-pb-3">
                      <KeyValueWidget
                        label={
                          <span className="gx-guarnic-headline-2">
                            <IntlMessages id="activeUserDashboard.schedule.eventType.label" />
                          </span>
                        }
                      />
                    </div>
                    <EventTypeList
                      triggers={schedule?.triggers}
                      layout={{ xs: 24, xl: 12, xxl: 12 }}
                    />
                  </div>
                </InfoCard>
              );
            })
          ) : (
            <Empty description={<IntlMessages id="execution.empty.state" />} />
          )}
        </BoxContainer>
      </BoxContainer>
    </Drawer>
  );
};

SchedulesActive.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
};

export default SchedulesActive;
