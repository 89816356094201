/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import moment from 'moment';
import { COMPONENT_TYPES } from '../../../../../eventTypes/constants';

const Date = ({ component }) => {
  const drawDate = (date, includesTime = false) => {
    if (includesTime) {
      return date ? moment(date.toDate()).utc().format('YYYY-MM-DD HH:mm') : '';
    }
    return date ? moment(date.toDate()).utc().format('YYYY-MM-DD') : '';
  };

  return (
    <div className="gx-mt-3">
      <h4>{component.label.name}</h4>
      <div>
        <p>
          {component.dateValue ? (
            drawDate(component.dateValue, component?.componentProps?.includesTime)
          ) : (
            <IntlMessages id="eventTypes.component.notAdded" />
          )}
        </p>
      </div>
    </div>
  );
};

Date.propTypes = {
  component: PropTypes.shape({
    componentProps: PropTypes.shape({
      includesTime: PropTypes.bool,
    }).isRequired,
    commonProps: PropTypes.shape({ required: PropTypes.bool.isRequired }).isRequired,
    label: PropTypes.shape({
      description: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
    type: COMPONENT_TYPES.DATE,
    dateValue: PropTypes.string.isRequired,
  }).isRequired,
};

export default React.memo(Date);
