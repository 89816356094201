import React from 'react';
import styles from './styles.module.less';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { circleButtonPropType } from 'packages/utils/proptypes/components';
import { useIsMobile } from 'packages/utils';

function CircleButton({
  iconName,
  iconNameText,
  className,
  color,
  label,
  type = 'primary',
  iconClassName = '',
  ...props
}) {
  const disabledStyle = props?.disabled ? styles.disabled : null;
  const onClick = props?.onClick;
  const tooltipText = props?.tooltipText || label || '';
  const { isMobile } = useIsMobile();

  const colorTypes = {
    primary: styles.primary,
    danger: styles.danger,
    dark: styles.dark,
    soft: styles.soft,
  };

  const backgroundTypes = {
    primary: styles.primaryBackground,
    danger: styles.dangerBackground,
    dark: styles.darkBackground,
    soft: styles.softBackground,
  };

  if (props?.hidden) return null;
  return (
    <Tooltip popupVisible={!isMobile} title={tooltipText}>
      <div className="gx-flex-column gx-justify-content-center gx-align-items-center">
        <div
          onClick={props?.disabled ? null : onClick}
          className={classNames(
            styles.circleMain,
            backgroundTypes[type || 'primary'],
            disabledStyle,
            onClick && 'gx-pointer',
            className,
          )}
        >
          {iconNameText ? (
            <span
              style={{ color: color }}
              className={classNames(colorTypes[type] || styles.primary, 'gx-fs-md', iconClassName)}
            >
              {iconNameText}
            </span>
          ) : (
            <span
              style={{ color: color }}
              className={classNames(
                'material-symbols-outlined',
                colorTypes[type] || styles.primary,
                iconClassName,
              )}
            >
              {iconName || 'manage_accounts'}
            </span>
          )}
        </div>
        {label && <div className="gx-d-flex gx-justify-content-center">{label}</div>}
      </div>
    </Tooltip>
  );
}

CircleButton.defaultProps = {
  color: null,
  disabled: false,
  hidden: false,
  label: '',
  iconNameText: '',
};

CircleButton.propTypes = { circleButtonPropType };

export default CircleButton;
