import React, { useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import styles from './styles.module.less';
import Proptypes from 'prop-types';
import classNames from 'classnames';
import { database } from 'firebase/firebase';
import { useSelector } from 'react-redux';
import { equalTo, orderByChild, query, ref } from 'firebase/database';
import useGetDataFromDatabase from 'packages/utils/hooks/useGetDataFromDatabase/useGetDataFromDatabase';
import MemberAvatar from 'components/MemberList/MemberAvatar';
import { divIcon } from 'leaflet';
import BoxContainer from 'components/BoxContainer';
import { RecenterAutomatically } from './RecenterAutomatically';
import { UserDetail } from './UserDetail';
import BaseLayers from './BaseLayers';
import FlyToMap from './FlyToMap';
import 'leaflet/dist/leaflet.css';
import { parseUserLocations } from './constants';

const UserLocationTracker = React.memo(({ size, users = [], currentUser }) => {
  const userIds = users.map(item => item.id);
  const orgId = useSelector(state => state.organizations.organization.id);
  const queryRefs = [query(ref(database, 'users'), orderByChild(orgId), equalTo(orgId))];

  const { data = [], loading, error } = useGetDataFromDatabase(queryRefs, [users]);
  const userLocations = useMemo(() => {
    return parseUserLocations(data) || [];
  }, [data]);

  const markers =
    !loading &&
    userLocations
      .map(record => {
        const userData = users.find(item => item.id === record.id);
        const userLocation = record?.positions?.UNIQUE_POSITION;

        if (userLocation) {
          return {
            id: userData?.id,
            geoCode: [userLocation?.l, userLocation.lg],
            photoURL: userData?.photoURL,
            displayName: userData?.displayName,
          };
        }
      })
      .filter(item => userIds.includes(item?.id)); // NOTE: here we are getting just users filtered by main view.

  const sizeClass = {
    xl: styles.xl,
    md: styles.md,
    sm: styles.sm,
  };

  //Custom Icon
  const iconMarkup = usr => renderToStaticMarkup(<MemberAvatar user={usr} size={40} />);
  const customIcon = usr => {
    const hasUserSelected = usr.id === currentUser?.id;
    // NOTE: class to located the user selected.
    const auxSelected = hasUserSelected
      ? {
          className: styles.userSelected,
        }
      : {};
    return new divIcon({
      html: iconMarkup(usr, hasUserSelected),
      ...auxSelected,
    });
  };

  const userFocused = React.useMemo(() => {
    const userFounded = userLocations.find(item => item?.id === currentUser?.id);

    if (!userFounded) return null;

    let userLocation = userFounded?.positions?.UNIQUE_POSITION;
    return [userLocation?.l, userLocation.lg];
  }, [currentUser]);

  return (
    <BoxContainer loading={loading && !error} error={!!error}>
      <MapContainer
        className={classNames(styles.mapContainer, sizeClass[size])}
        center={(markers && markers[0]?.geoCode) || [51.505, -0.09]}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {markers.length > 0 &&
          markers.map(marker => (
            <Marker key={marker.id} position={marker.geoCode} icon={customIcon(marker)}>
              <Popup className={styles.userDetailPopup}>
                <UserDetail user={marker} />
              </Popup>
            </Marker>
          ))}
        {!loading && markers.length > 0 && <RecenterAutomatically geoCode={markers[0]?.geoCode} />}
        <BaseLayers />
        <FlyToMap position={userFocused} />
      </MapContainer>
    </BoxContainer>
  );
});

UserLocationTracker.defaultProps = {
  size: 'xl',
  currentUser: null,
  users: [],
};

UserLocationTracker.propTypes = {
  size: Proptypes.string,
  currentUser: Proptypes.any,
  users: Proptypes.array,
};

export default UserLocationTracker;
