import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.less';
import { KeyValueWidget } from 'components/KeyValueWidget';
import moment from 'moment';
import IntlMessages from 'util/IntlMessages';
import ConnectionStatus from 'components/Widget/ConnectionStatus';
import BateryStatus from 'components/Widget/BateryStatus';
import LastConnection from 'components/Widget/LastConnection';
import { useIsMobile } from 'packages/utils';

export const UserDetail = ({ user }) => {
  const { isMobile } = useIsMobile();
  //TODO: To be defined this object.
  const phoneDetails = {
    altitude: '10 m',
    accuracy: '15 m',
    speed: '8 km',
    connected: true,
    batery: 0,
    lastLogin: moment().toDate(),
    time: moment().format('h:mm'),
  };

  return (
    <div className={styles.userDetailMain}>
      <div className={styles.blockA}>
        <KeyValueWidget label={user?.displayName} />
        <KeyValueWidget labelClass={styles.popupUserTime} label={phoneDetails.time} />
      </div>
      <div className={styles.blockB}>
        <KeyValueWidget
          oneLine
          label={<IntlMessages id="common.altitude" />}
          value={phoneDetails.altitude}
        />
        <KeyValueWidget
          oneLine
          label={<IntlMessages id="common.accuracy" />}
          value={phoneDetails.accuracy}
        />
        <KeyValueWidget
          oneLine
          label={<IntlMessages id="common.speed" />}
          value={phoneDetails.speed}
        />
      </div>
      <div className={styles.blockC}>
        <div className="gx-flex-row">
          <ConnectionStatus
            className={!isMobile ? 'gx-pr-2' : 'gx-guarnic-pb-1'}
            connected={phoneDetails.connected}
          />
          <BateryStatus
            className={!isMobile ? 'gx-pr-2' : 'gx-guarnic-pb-1'}
            batery={phoneDetails.batery}
          />
          <LastConnection lastLogin={phoneDetails.lastLogin} />
        </div>
      </div>
    </div>
  );
};

UserDetail.propTypes = {
  user: PropTypes.any, //TODO: defined when it is ready.
};
