import GroupsSelected from 'components/Users/GroupsSelected';
import UsersSelected from 'components/Users/UsersSelected';
import React from 'react';
import { MemeberPrototypes } from 'packages/utils/proptypes/user';

export default function Members({ members }) {
  const { users = [], groups = [] } = members;
  return (
    <div className="gx-flex-column gx-guarnic-gap-1">
      <UsersSelected users={users} />
      <GroupsSelected groups={groups} />
    </div>
  );
}

Members.defaultProps = {
  members: {
    users: [],
    groups: [],
  },
};

Members.propTypes = {
  members: MemeberPrototypes,
};
