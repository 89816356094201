import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

export const RecenterAutomatically = ({ geoCode }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(geoCode);
  }, []);
  return null;
};
