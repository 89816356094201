import React from 'react';
import { Avatar } from 'antd';
import { AimOutlined, QrcodeOutlined } from '@ant-design/icons';
import { LOC_TYPES } from 'packages/locations';

import styles from './styles.module.less';
import classNames from 'classnames';

const ICONS = {
  GPS: <AimOutlined />,
  QR: <QrcodeOutlined />,
  NFC: <span style={{ fontSize: '8px' }}>{LOC_TYPES.NFC}</span>,
};

const variants = {
  primary: styles.primary,
  light: styles.light,
};

const AvatarLocation = ({ locationType, type, onClick }) => {
  return (
    <Avatar
      onClick={onClick}
      className={classNames(styles.locationAvatar, variants[type || 'primary'])}
      icon={ICONS[locationType?.toUpperCase()]}
      size={24}
    />
  );
};

export default AvatarLocation;
