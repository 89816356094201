import { Input, Select } from 'antd';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { STATUS_OPTIONS } from 'components/Users/constants';
import React from 'react';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';

const statusOptions = [
  { value: STATUS_OPTIONS.AVAILABLE, label: <IntlMessages id="user.status.available" /> },
  { value: STATUS_OPTIONS.UNAVAILABLE, label: <IntlMessages id="user.status.unavailable" /> },
  { value: STATUS_OPTIONS.UNKNOWN, label: <IntlMessages id="user.status.unknown" /> },
];

export default function SelectMembersFilters({
  onSearch,
  onStatus,
  statusValue,
  searchValue,
  includeStatus = true,
  isGroups = false,
}) {
  const searchLabel = isGroups ? (
    <IntlMessages id="groups.select.table.groups" />
  ) : (
    <IntlMessages id="user.users.groups" />
  );

  return (
    <div className="gx-flex-row gx-guarnic-mt-2 gx-guarnic-mb-2">
      <KeyValueWidget
        keyProp="members"
        label={searchLabel}
        value={
          <Input.Search
            value={searchValue}
            onChange={e => onSearch(e.target.value)}
            className="gx-mb-0 gx-w-100"
          />
        }
        className="gx-flex-1 gx-pr-sm-1 gx-mb-1"
      />
      {includeStatus && (
        <KeyValueWidget
          className="gx-flex-1"
          keyProp="status"
          label={<IntlMessages id="user.status" />}
          value={
            <Select
              defaultValue={statusValue}
              value={statusValue}
              onChange={value => onStatus(value)}
              options={statusOptions}
              className="gx-w-100"
            />
          }
        />
      )}
    </div>
  );
}

SelectMembersFilters.defaultProps = {
  includeStatus: true,
  isGroups: false,
};

SelectMembersFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onStatus: PropTypes.func.isRequired,
  statusValue: PropTypes.string.isRequired,
  searchValue: PropTypes.string.isRequired,
  includeStatus: PropTypes.bool,
  isGroups: PropTypes.bool,
};
