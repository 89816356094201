import React, { useEffect, useState } from 'react';

import { Drawer, Form, Tabs } from 'antd';

import IntlMessages from 'util/IntlMessages';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { getWorkOrderById } from 'util/firebase-operations/executions/getWorkOrder';

import { useParams, useHistory } from 'react-router-dom';
import { executionFactory } from 'packages/utils/functions/executions';
import {
  FORMS,
  getToWorkOrderList,
  STATUS_ALLOWED_TO_REFRESH,
  TASK_DETAILS_TABS,
  taskDetailsFactory,
} from 'packages/workOrders/constants';
import { useIsMobile, useToggle } from 'packages/utils';
import { doc, updateDoc } from 'firebase/firestore';
import { sendErrorToSentry } from 'util/sentry/utils';
import { db } from 'firebase/firebase';
import { createScheduleOptions } from 'util/firebase-operations/schedule/create';
import { ACTION_SCHEDULE_OPTIONS } from 'packages/schedules/constants';
import CircleButton from 'components/Buttons/CircleButton';
import useRemoteConfig from 'packages/utils/hooks/useRemoteConfig';
import Members from './Members';
import styles from './styles.module.less';
import GeneralInformation from './GeneralInformation';
import MembersForm from '../MembersForm';

const TaskDetails = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [membersFormVisible, membersFormToggle] = useToggle(false);
  const [tabActive, setTabActive] = useState(TASK_DETAILS_TABS.GENERAL);
  const [refresh, refreshToggle] = useToggle(false);

  const [membersForm] = Form.useForm();
  const navigate = useHistory();
  const { divId, orderId, orgId } = useParams();
  const { general = {}, members = {} } = data || {};
  const { isMobile } = useIsMobile();
  const { value: switchControlValue, loading: switchControlLoading } =
    useRemoteConfig('switch_users_active');

  const mainLoading = loading || switchControlLoading;
  const itemViews = [
    {
      label: <IntlMessages id="work.order.details.general" />,
      key: TASK_DETAILS_TABS.GENERAL,
      children: <GeneralInformation data={general} />,
    },
    {
      label: <IntlMessages id="work.order.details.members" />,
      key: TASK_DETAILS_TABS.MEMBERS,
      children: <Members members={members} />,
    },
  ];
  const taskStatus = data?.general?.status || false;
  const hiddenSwitchUserButton =
    tabActive !== TASK_DETAILS_TABS.MEMBERS ||
    !STATUS_ALLOWED_TO_REFRESH.includes(taskStatus) ||
    !switchControlValue;

  const onCloseDrawer = () => navigate.push(getToWorkOrderList(orgId));

  const onFormFinish = async (_, { forms }) => {
    const newMembers = forms[FORMS.MEMBERS].getFieldsValue(['groups', 'users']);

    try {
      setLoading(true);
      const docRef = doc(
        db,
        'organizations',
        orgId,
        'divisions',
        divId,
        'schedules',
        data.general.schedule.id,
      );
      await updateDoc(docRef, { members: newMembers });
      await createScheduleOptions({
        data: { action: ACTION_SCHEDULE_OPTIONS.now },
        orgId,
        divId,
        scheduleId: data.general.schedule.id,
      });
      await new Promise(resolve => setTimeout(resolve, 3000));
      refreshToggle();

      setLoading(false);
      membersFormToggle();
    } catch (error) {
      setLoading(false);
      sendErrorToSentry(error, { functionName: 'onFormFinish' });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!orgId || !divId || !orderId) return;
      setLoading(true);
      const result = await getWorkOrderById(orgId, divId, orderId);
      const dataBuilded = await executionFactory([result]);
      const dataParsed = taskDetailsFactory(dataBuilded[0]);
      if (dataParsed) {
        setData(dataParsed);
      }
      setLoading(prev => !prev);
    };
    fetchData();
  }, [divId, orderId, orgId, refresh]);

  return (
    <Drawer
      onClose={onCloseDrawer}
      className={styles.mainContainer}
      open
      closable={false}
      footer={null}
    >
      <Form.Provider onFormFinish={onFormFinish}>
        <BoxContainer
          loading={mainLoading}
          bottomSpacing={{
            active: isMobile,
            content: (
              <div className="gx-d-flex gx-guarnic-gap-1">
                <CircleButton
                  label={<IntlMessages id="work.order.replace.user.label" />}
                  onClick={() => membersFormToggle()}
                  iconName="manage_accounts"
                  hidden={hiddenSwitchUserButton}
                />
                <CircleButton
                  label={<IntlMessages id="button.goBack" />}
                  onClick={onCloseDrawer}
                  iconName="reply"
                  type="danger"
                />
              </div>
            ),
          }}
        >
          <BoxContainer content>
            <FilterContainer
              title={<Title.Header value={general?.schedule?.name} />}
              goBack={onCloseDrawer}
              buttonItems={[
                {
                  tooltipText: <IntlMessages id="work.order.replace.user.label" />,
                  action: () => membersFormToggle(),
                  iconName: 'manage_accounts',
                  hidden: hiddenSwitchUserButton || isMobile,
                },
              ]}
            />
          </BoxContainer>
          <BoxContainer content>
            {!mainLoading && Object.values(data).length > 0 && (
              <Tabs
                onChange={setTabActive}
                defaultActiveKey={TASK_DETAILS_TABS.GENERAL}
                items={itemViews}
              />
            )}
          </BoxContainer>
        </BoxContainer>

        {general?.divId && membersFormVisible && (
          <MembersForm
            defaultMembers={general?.schedule?.members}
            divId={general?.divId}
            isVisible={membersFormVisible}
            onCancel={() => membersFormToggle()}
            form={membersForm}
            loading={mainLoading}
          />
        )}
      </Form.Provider>
    </Drawer>
  );
};

export default React.memo(TaskDetails);
