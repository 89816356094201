import React, { useEffect, useState } from 'react';
import IntlMessages from 'util/IntlMessages';
import { Table, Pagination } from 'antd';
import { useFirestoreRealtimeBatchedPagination, useIsMobile } from 'packages/utils';
import classNames from 'classnames';
// import { ResponsiveContainer } from 'recharts';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { useSelector } from 'react-redux';
import { collection, query } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import MemberAvatar from 'components/MemberList/MemberAvatar';
import { CalendarOutlined, UnorderedListOutlined, AimOutlined } from '@ant-design/icons';
import SchedulesActive from 'packages/dashboard/components/ActiveUserDashboard/SchedulesActive';
import UserLocationTracker from 'components/Maps/UserLocationTracker';
import TimelineByUser from 'packages/dashboard/components/ActiveUserDashboard/TimelineByUser';
import useRemoteConfig from 'packages/utils/hooks/useRemoteConfig';
import { useHistory } from 'react-router-dom';
import ActiveUserFilter from '../../components/ActiveUserDashboard/ActiveUserFilter';
import styles from './styles.module.less';
import { STATUS_PER_DEFAULT, USER_DISPLAY_PER_DEFAULT } from './constants';
// import PercentagePieChart from '../../../../components/PercentagePieChart';

const ActiveUserDashboard = () => {
  const { isMobile } = useIsMobile();
  const { value: userAlloweds, isLoaded } = useRemoteConfig('active_user_view');

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState({
    text: '',
    status: STATUS_PER_DEFAULT, // NOTE: "actives" or "inactive"
    userPerDisplay: USER_DISPLAY_PER_DEFAULT,
  });
  const [scheduleByUserOpen, setScheduleByUserOpen] = useState(false);
  const [timelineByUserVisible, setTimelineByUserVisible] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [userFocused, setUserFocused] = useState(null);

  // Selectors
  const orgId = useSelector(state => state.organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids || []);

  // Handlers
  const handleSchedulesByUser = user => {
    setScheduleByUserOpen(true);
    setUserSelected(user);
  };

  const handleTimelineByUser = user => {
    setTimelineByUserVisible(true);
    setUserSelected(user);
  };

  const handleFindUser = user => {
    // NOTE: we are going to evaluate if are equals we are going to unchecked the marker on the map
    setUserFocused(prev => (prev?.id === user?.id ? null : user));
  };

  // rest
  const queryByDivId = {};
  selectedDivsIds.forEach(divId => {
    queryByDivId[divId] = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'users'),
    );
  });

  const {
    data: users,
    next,
    loading,
    gotNewData,
  } = useFirestoreRealtimeBatchedPagination(
    [query(collection(db, 'organizations', orgId, 'users'))],
    [orgId],
  );
  //

  const USER_COLUMNS = [
    {
      title: <IntlMessages id="activeUserDashboard.user.column" />,
      dataIndex: 'displayName',
      key: 'displayName',
      fixed: 'left',
      width: isMobile ? 140 : 170,
      render: (value, user) => (
        <div className="gx-flex-row">
          {!isMobile && <MemberAvatar user={user} />}
          <div className={classNames('gx-align-self-center gx-pl-2', styles.userColumn)}>
            {value}
          </div>
        </div>
      ),
    },
    // {
    //   title: <IntlMessages id="activeUserDashboard.schedule.column" />,
    //   dataIndex: 'schedule',
    //   key: 'schedule',
    //   width: 100,
    //   render: () => (
    //     <ResponsiveContainer width="100%" height={70}>
    //       <PercentagePieChart value={30} percentage={25.5} />
    //     </ResponsiveContainer>
    //   ),
    // },
    // {
    //   title: <IntlMessages id="activeUserDashboard.event.column" />,
    //   dataIndex: 'event',
    //   key: 'event',
    //   width: 170,
    // },
    // {
    //   title: <IntlMessages id="activeUserDashboard.ontrack.column" />,
    //   dataIndex: 'onTrack',
    //   key: 'onTrack',
    //   width: 100,
    // },
    // {
    //   title: <IntlMessages id="activeUserDashboard.lastlogin.column" />,
    //   dataIndex: 'lastLogin',
    //   key: 'lastLogin',
    //   width: 200,
    // },
    // {
    //   title: <IntlMessages id="activeUserDashboard.connected.column" />,
    //   dataIndex: 'connected',
    //   key: 'connected',
    //   width: 120,
    // },
    {
      dataIndex: 'scheduleDetails',
      key: 'scheduleDetails',
      width: 40,
      render: (_, user) => (
        <CalendarOutlined
          className={styles.auxColumns}
          onClick={() => handleSchedulesByUser(user)}
        />
      ),
    },
    {
      dataIndex: 'timelineDetails',
      key: 'timelineDetails',
      width: 40,
      render: (_, user) => (
        <UnorderedListOutlined
          className={styles.auxColumns}
          onClick={() => handleTimelineByUser(user)}
        />
      ),
    },
    {
      dataIndex: 'findUser',
      key: 'findUser',
      width: 40,
      render: (_, user) => (
        <AimOutlined className={styles.auxColumns} onClick={() => handleFindUser(user)} />
      ),
    },
  ];

  // NOTE: FILTERING BY DIVISIONS
  let usersParsed = users.filter(usr =>
    Object.keys(usr.divisions).some(divId => selectedDivsIds.includes(divId)),
  );
  usersParsed = usersParsed.map(({ id, displayName, photoURL = '', divisions }) => ({
    key: id,
    id,
    displayName,
    schedule: null, /// TODO: create formule
    event: null, /// TODO: create formule,
    onTrack: null, // TODO: create formule,
    lastLogin: null, // TODO: create formule,
    connected: null, // TODO: create formule,
    divisions,
    // extraFields
    photoURL,
  }));

  // NOTE: Control Pagination
  const pageSize = filters.userPerDisplay;
  const usersParsedRef = usersParsed;
  const handlePageChange = page => {
    setCurrentPage(page);

    if (gotNewData && !loading) next();
  };
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;
  usersParsed = usersParsed.slice(start, end);

  // Filtering users
  usersParsed = React.useMemo(() => {
    const regexByText = new RegExp(filters.text, 'i');
    const output = usersParsed?.filter(usr => {
      const userName = usr?.displayName.toLowerCase();

      return regexByText.test(userName) || userName.includes(filters.text);
    });

    if (filters.userPerDisplay === 0) {
      return output;
    }
    return output.slice(0, filters.userPerDisplay); // NOTE: filter by user to display (count)
  }, [filters.text, filters.userPerDisplay, usersParsed]);

  // Manual Filter
  const handleFilter = (field, value) => {
    setFilters(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    if (!userAlloweds && isLoaded) {
      history.push('/');
    }
  }, [history, isLoaded, userAlloweds]);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          title={<Title.Header value={<IntlMessages id="dashboards.active_users" />} />}
          description={<IntlMessages id={`dashboards.active_user.status.${filters.status}`} />}
          content={<ActiveUserFilter onChangeFilter={handleFilter} />}
          showHide
        />
      </BoxContainer>
      <BoxContainer content loading={loading}>
        <UserLocationTracker
          size="md"
          users={usersParsed}
          onFindUser={handleFindUser}
          currentUser={userFocused}
        />
        <Table
          className={styles.usersTable}
          columns={USER_COLUMNS}
          dataSource={usersParsed}
          scroll={{ x: 650, y: 240 }}
          footer={() => (
            <Pagination
              current={currentPage}
              onChange={handlePageChange}
              pageSize={pageSize}
              total={usersParsedRef.length || 0}
              size="small"
            />
          )}
          pagination={false} // NOTE: set false because we are using a Pagination customized.
          loading={loading}
        />
      </BoxContainer>
      {scheduleByUserOpen && !loading && (
        <SchedulesActive
          user={userSelected}
          isVisible={scheduleByUserOpen}
          onCancel={setScheduleByUserOpen}
        />
      )}
      {timelineByUserVisible && (
        <TimelineByUser
          user={userSelected}
          isVisible={timelineByUserVisible}
          onCancel={setTimelineByUserVisible}
        />
      )}
    </BoxContainer>
  );
};

export default ActiveUserDashboard;
