import React from 'react';
import styles from './styles.module.less';
import classNames from 'classnames';

export default function BottonSpacing({ children, align = 'center' }) {
  const alignClass = align === 'center' ? styles.alignCenter : styles.alignLeft;
  return (
    <div className={styles.bottonSpacingMain}>
      <div className="gx-d-flex gx-w-100">
        <div className={classNames(styles.box, alignClass)}>{children}</div>
      </div>
    </div>
  );
}
