import { ChildrenSchema } from 'packages/utils/schemas';
import PropTypes from 'prop-types';
import { actionButton } from './button';

export const infoCardTypeProptype = PropTypes.oneOf(['primary', 'light']);

export const infoCardProptypes = {
  children: ChildrenSchema,
  classNames: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  actionButtons: PropTypes.arrayOf(actionButton),
  type: infoCardTypeProptype,
  extraRightContent: PropTypes.any,
};
