import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import IntlMessages from 'util/IntlMessages';
import {
  ALLOWED_ROLES,
  useFirestoreQueryBatchedPagination,
  getDivsWithAccess,
} from 'packages/utils';
import { db } from 'firebase/firebase';
import { query, orderBy, collection } from 'firebase/firestore';
import InfiniteScroll from 'components/InfiniteScroll';

import BoxContainer from 'components/BoxContainer';

import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { PATHS } from '../../constants';
import Group from '../../components/Group';

const GroupsList = () => {
  const history = useHistory();

  const loadedGroups = useSelector(({ groups }) => groups.group.save.loaded);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);
  const orgId = useSelector(state => state.organizations.organization.id);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);

  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const allowedDivsIds = allowedDivs.map(d => d.id);

  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));
  const queriesList = allowedSelectedDivs.map(divId =>
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'groups'),
      orderBy('createdAt'),
    ),
  );

  const {
    data: groups,
    loading: groupsLoading,
    next,
    gotNewData,
    error,
  } = useFirestoreQueryBatchedPagination(queriesList, [selectedDivsIds, loadedGroups]);

  const handleCreateGroup = () => history.push(PATHS.CREATE_GROUP);

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          title={<Title.Header value={<IntlMessages id="groups.list.title" />} />}
          actionButtons={[
            {
              label: <IntlMessages id="button.create" />,
              action: handleCreateGroup,
              allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.GROUPS.CREATE,
              type: 'primary',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={groupsLoading}>
        {groups.map(group => (
          <Group key={group.id} group={group} />
        ))}
        {!groupsLoading && error && (
          <h2>
            <IntlMessages id="notification.somethingWentWrong" />
          </h2>
        )}
        <InfiniteScroll condition={!groupsLoading && gotNewData} callback={next} />
      </BoxContainer>
    </BoxContainer>
  );
};

export default GroupsList;
