import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import classNames from 'classnames';

const LastConnection = ({ lastLogin, className }) => {
  if (!lastLogin) return null;
  return (
    <div className={classNames('gx-flex-row', className)}>
      <div className="gx-flex-row gx-align-content-center">
        <span className="material-icons-outlined">logout</span>
        <div className="gx-flex-row gx-align-content-center gx-pl-1 gx-pr-1">
          <IntlMessages id="common.lastlogin" values={{ date: lastLogin }} />
        </div>
      </div>
    </div>
  );
};

LastConnection.defaultProps = {
  className: null,
};
LastConnection.propTypes = {
  lastLogin: PropTypes.any.isRequired,
  className: PropTypes.string,
};

export default LastConnection;
