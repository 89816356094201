import React from 'react';
import { Radio } from 'antd';
import IntlMessages from 'util/IntlMessages';

const RELATIVE_ABSOLUTE = [
  {
    label: 'relative',
    value: true,
  },
  {
    label: 'absolute',
    value: false,
  },
];

const RelativeAbsolute = ({ onChange, defaultValue, ...props }) => {
  return (
    <Radio.Group {...props} className="gx-w-100" onChange={onChange} defaultValue={defaultValue}>
      {RELATIVE_ABSOLUTE.map(({ label, value }) => (
        <Radio.Button className="gx-w-50 gx-text-center" key={label} value={value}>
          <span style={{ fontSize: '12px' }}>
            <IntlMessages id={`component.${label}`} />
          </span>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default RelativeAbsolute;
