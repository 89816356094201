import { db } from 'firebase/firebase';
import { doc, getDoc } from 'firebase/firestore';

import { sendErrorToSentry } from 'util/sentry/utils';
import { DIVISIONS, EXECUTIONS, ORGANIZATIONS } from '../constants';

const getWorkOrderById = async (orgId, divId, workOrderId) => {
  const workOrderRef = doc(db, ORGANIZATIONS, orgId, DIVISIONS, divId, EXECUTIONS, workOrderId);

  return getDoc(workOrderRef)
    .then(res => {
      if (res.exists()) {
        return res.data();
      }

      return null;
    })
    .catch(err => {
      sendErrorToSentry(err, { functionName: 'getWorkOrderById' });
      return err;
    });
};

export { getWorkOrderById };
