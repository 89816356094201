import React from 'react';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import PropTypes from 'prop-types';
import styles from './styles.module.less';
import classNames from 'classnames';
import { ALIGN_CLASSES } from './constants';
import { Spin } from 'antd';

const ResponsiveTable = ({ dataSource, columns, className, loading = false }) => {
  if (loading) return <Spin />;
  return (
    <Table className={classNames(styles.tableContainer, className)}>
      <Thead>
        <Tr>
          {columns.map(column => (
            <Th
              style={{ width: column?.width }}
              className={classNames(ALIGN_CLASSES[column.align])}
              key={column.key}
            >
              {column.title}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {dataSource.map(data => (
          <Tr key={data.id}>
            {columns.map(column => (
              <Td className={classNames(ALIGN_CLASSES[data.align])} key={column.key}>
                {column.render ? column.render(data) : data[column.dataIndex]}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

ResponsiveTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      dataIndex: PropTypes.string.isRequired,
      render: PropTypes.func,
      width: PropTypes.any,
      align: PropTypes.oneOf(['left', 'center', 'right']),
    }),
  ).isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
};

export default ResponsiveTable;
