import React, { useEffect, useState } from 'react';
import { Form, Drawer, Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import IntlMessages from 'util/IntlMessages';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import SelectMembersFilters from 'components/Users/SelectMembersFilters';
import SelectUsersTable from 'components/Users/SelectUsersTable';
import SelectGroupsTable from 'components/Users/SelectGroupsTable';
import { STATUS_OPTIONS } from 'components/Users/constants';
import useGetSelectUsers from 'packages/utils/hooks/collections/useGetSelectUsers';
import UsersSelected from 'components/Users/UsersSelected';
import GroupsSelected from 'components/Users/GroupsSelected';
import styles from './styles.module.less';

import { FORMS } from '../../constants';
import { useResetFormOnCloseModal } from '../../util';

const { Item } = Form;
const MembersForm = ({ isVisible, onCancel, divId, defaultMembers, onlyRead }) => {
  const [form] = Form.useForm();
  const [selectedGroups, setSelectedGroups] = useState(defaultMembers.groups);
  const [selectedUsers, setSelectedUsers] = useState(defaultMembers.users);
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState(STATUS_OPTIONS.AVAILABLE);

  useEffect(() => {
    if (isVisible) {
      setSelectedGroups(defaultMembers.groups);
      setSelectedUsers(defaultMembers.users);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const {
    data: { usersDetails, groups },
    loading: usersLoadingTable,
  } = useGetSelectUsers({ orgId, divId });

  // Get all users from selected groups
  const usersByGroup = selectedGroups.reduce((acc, group) => {
    acc.push(...group.users);
    return acc;
  }, []);
  // Merge selected users with users from selected groups
  const allUsersSelected = [...selectedUsers, ...usersByGroup];
  // Remove duplicated users
  const uniqueUsersSelected = Array.from(new Set(allUsersSelected.map(user => user.id))).map(id =>
    allUsersSelected.find(user => user.id === id),
  );

  const usersDetailsFiltered = usersDetails.filter(
    usr =>
      !uniqueUsersSelected.some(user => user.id === usr.id) &&
      (usr.displayName.toLowerCase().includes(search.toLowerCase()) ||
        usr?.group?.groupsByUser?.some(group =>
          group.name.toLowerCase().includes(search.toLowerCase()),
        )) &&
      usr.status === status,
  );
  const groupsFiltered = groups.filter(
    grp =>
      !selectedGroups.some(item => item.id === grp.id) &&
      grp.name.toLowerCase().includes(search.toLowerCase()),
  );

  const onOk = () => {
    form.setFieldsValue({
      groups: selectedGroups,
      users: selectedUsers,
    });
    form.submit();
  };

  const addUser = values => {
    setSelectedUsers([...selectedUsers, values.user]);
  };
  const addGroup = value => {
    setSelectedGroups([...selectedGroups, value]);
  };

  const membersTabsOptions = [
    {
      label: 'Users',
      key: 'users',
      children: (
        <>
          <SelectMembersFilters
            onSearch={setSearch}
            onStatus={setStatus}
            statusValue={status}
            searchValue={search}
            isGroups={false}
          />
          <SelectUsersTable
            onAdd={addUser}
            loading={usersLoadingTable}
            data={usersDetailsFiltered}
          />
        </>
      ),
    },
    {
      label: 'Groups',
      key: 'groups',
      children: (
        <>
          <SelectMembersFilters
            onSearch={setSearch}
            onStatus={setStatus}
            statusValue={status}
            searchValue={search}
            includeStatus={false}
            isGroups
          />
          <SelectGroupsTable onAdd={addGroup} loading={usersLoadingTable} data={groupsFiltered} />
        </>
      ),
    },
  ];

  const handleRemoveUser = userId => {
    setSelectedUsers(selectedUsers.filter(user => user.id !== userId));
  };
  const handleRemoveGroup = groupId => {
    setSelectedGroups(selectedGroups.filter(group => group.id !== groupId));
  };

  useResetFormOnCloseModal({
    form,
    isVisible,
  });

  return (
    <Drawer className={styles.mainContainer} open={isVisible} closable={false} footer={null}>
      <BoxContainer>
        <BoxContainer content fixed>
          <FilterContainer
            goBack={onCancel}
            title={
              <Title.Header
                className="gx-pt-1 gx-pb-1"
                value={<IntlMessages id="schedule.form.members.modal.title" />}
              />
            }
            actionButtons={[
              {
                label: <IntlMessages id="form.save" />,
                action: onOk,
                type: 'primary',
              },
            ]}
          />
        </BoxContainer>
        <BoxContainer content>
          {/* Users Assigned */}
          <UsersSelected users={selectedUsers} onRemoveUser={onlyRead ? null : handleRemoveUser} />
          {/* Groups Assigned */}
          <GroupsSelected
            className="gx-guarnic-pt-3"
            groups={selectedGroups}
            onRemoveGroup={onlyRead ? null : handleRemoveGroup}
          />
          {!onlyRead && (
            <Form
              className={styles.customMembersForm}
              form={form}
              name={FORMS.MEMBERS}
              layout="vertical"
            >
              <Item
                label={
                  <Title.LabelForm
                    value={<IntlMessages id="schedule.form.members.modal.addMmebers.label" />}
                  />
                }
                name="members"
                className="gx-guarnic-mt-2"
              >
                <Tabs
                  onChange={() => {
                    setSearch('');
                    setStatus(STATUS_OPTIONS.AVAILABLE);
                  }}
                  defaultActiveKey="1"
                  items={membersTabsOptions}
                />
              </Item>
            </Form>
          )}
        </BoxContainer>
      </BoxContainer>
    </Drawer>
  );
};

MembersForm.propTypes = {
  divId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onlyRead: PropTypes.bool,

  defaultMembers: PropTypes.shape({
    users: PropTypes.arrayOf(
      PropTypes.shape({
        displayName: PropTypes.string.isRequired,
        email: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        photoURL: PropTypes.string,
      }),
    ),
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        avatarURL: PropTypes.string,
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
};

MembersForm.defaultProps = {
  defaultMembers: {
    users: [],
    groups: [],
  },
  onlyRead: false,
};

export default MembersForm;
