import React from 'react';
import PropTypes from 'prop-types';
import { InfoCard } from 'components/InfoCard';
import { useSelector } from 'react-redux';
import { ScheduleInfo } from 'components/schedule';
import Members from 'components/MemberList/Members';
import ProgressBar from 'components/ProgressBar';
import { scheduleProptype } from 'packages/utils/proptypes/schedule';
import EventTypeList from 'components/Events/EventTypeList';
import { useToggle } from 'packages/utils';

import Title from 'components/BoxContainer/components/Title';
import IntlMessages from 'util/IntlMessages';
import { calculateScheduleProgress } from '../../constants';

function ProgressItemV2({
  schedule = {},
  aggregation = {},
  onOpenEventTypes = null,
  onOpenTimeline = null,
}) {
  const [visible, visibleToggle] = useToggle(false);

  const orgId = useSelector(state => state.organizations.organization.id);
  const divisions = useSelector(({ user }) => user.profile.data.organizations[orgId]?.divisions);
  const scheduleData = calculateScheduleProgress(aggregation);
  const totalFinished = aggregation?.triggersNested?.finishedExecutions?.value || 0;
  const totalEvents = `${totalFinished}/${
    aggregation?.triggersNested?.expectedExecutions?.value || 0
  }`;

  const { id = '', name: scheduleName, duration, members = {} } = schedule || {};

  const getDivisionById = divId => divisions[divId];

  const triggersById =
    schedule?.triggers?.length > 0 &&
    schedule?.triggers?.reduce((prevValue, trigger) => {
      const copy = { ...prevValue };

      copy[trigger.id] = {
        trigger,
      };
      return copy;
    }, {});

  const triggersAndAgreggation = scheduleData.triggers.map(trigger => {
    const triggerBySch = triggersById[trigger.key];
    return {
      ...triggerBySch?.trigger,
      finishedExecutions: trigger.finishedTriggerExecutions?.value || 0,
      expectedExecutions: trigger.expectedTriggerExecutions?.value || 0,
      finishedPercentage: trigger.triggerFinishedPercentage?.value || 0,
    };
  });

  if (Object.keys(schedule).length === 0) {
    return <></>;
  }
  return (
    <>
      <InfoCard
        key={id}
        title={
          <div className="gx-flex-xl-row gx-flex-column gx-guarnic-gap-1">
            <InfoCard.Title value={scheduleName} />
          </div>
        }
        buttonItems={[
          {
            iconName: !visible ? 'keyboard_arrow_right' : 'keyboard_arrow_down',
            action: () => visibleToggle(),
            type: 'dark',
            iconClassName: 'gx-fs-xlxl',
          },
        ]}
      >
        <ScheduleInfo
          key={id}
          startDate={schedule?.schedule?.startAt?.toDate()}
          endDate={schedule?.schedule?.endAt?.toDate()}
          divisionName={getDivisionById(schedule?.divId)?.name}
          duration={duration}
          schedule={schedule?.schedule}
          layout={{ xs: 12, xl: 4, xxl: 4 }}
          type={schedule?.type}
          totalEvents={{
            value: totalEvents,
            onClick:
              totalFinished > 0 && onOpenTimeline
                ? () => onOpenTimeline(schedule.divId, schedule.id)
                : null,
          }}
          wrap
          recurrence={false}
        />
        <div className="gx-guarnic-pt-3">
          <Members keyProp={id} users={members?.users} groups={members?.groups} />
        </div>
        {visible && (
          <div className="gx-guarnic-pt-3">
            <Title.SubTitle className="gx-mb-2" value={<IntlMessages id="general.eventTypes" />} />
            <EventTypeList.SimpleList
              wrapperData
              data={triggersAndAgreggation}
              infoCardType="light"
              limitOptions={{ mobile: 3, desktop: 5 }}
              onSeeMore={
                onOpenEventTypes
                  ? () => onOpenEventTypes({ schedule, triggersAndAgreggation })
                  : null
              }
              onGoToTimeline={
                onOpenTimeline
                  ? triggerId => onOpenTimeline(schedule.divId, schedule.id, triggerId)
                  : null
              }
            />
          </div>
        )}
        <div className="gx-guarnic-pt-3">
          <ProgressBar percentage={scheduleData.finishedPercentage} status={scheduleData.status} />
        </div>
      </InfoCard>
    </>
  );
}

ProgressItemV2.propTypes = {
  schedule: scheduleProptype.isRequired,
  aggregation: PropTypes.object.isRequired,
  onOpenEventTypes: PropTypes.func,
  onOpenTimeline: PropTypes.func,
};

export default ProgressItemV2;
