import { ALLOWED_ROLES } from 'packages/utils';

import { PATHS } from './constants';
import WorkOrderList from './screens/WorkOrderList';
import WorkOrder from './screens/WorkOrder';
import TaskDetails from './components/TaskDetails';

export default [
  {
    component: WorkOrderList,
    path: PATHS.BASE_URL,
    exact: true,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_ORDER.LIST,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: WorkOrder,
    path: PATHS.CREATE_ORDER_BY_TEMPLATE,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_ORDER.CREATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: WorkOrder,
    path: PATHS.CREATE_ORDER,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_ORDER.CREATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
  {
    component: TaskDetails,
    path: PATHS.DETAILS,
    allowedRoles: ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.WORK_ORDER.UPDATE,
    onOrgChangeRedirectTo: `/:orgId/-/${PATHS.BASE_URL}`,
  },
];
