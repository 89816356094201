import { useCallback, useEffect, useState } from 'react';

const useGetCurrentLocation = dependencies => {
  const [currentLocation, setCurrentLocation] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchCurrentLocation = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const position = await new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
          pos => resolve([pos.coords.latitude, pos.coords.longitude]),
          err => reject(err),
        ),
      );
      setCurrentLocation(position);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (dependencies?.length > 0) {
      fetchCurrentLocation().catch(err => {
        if (isMounted) setError(err);
      });
    }

    return () => {
      isMounted = false;
      setError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);

  return {
    currentLocation,
    error,
    loading,
  };
};

export default useGetCurrentLocation;
