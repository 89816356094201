import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.less';
import ComponentBlocker from 'components/ComponentBlocker';
import { Button, Modal } from 'antd';
import { EditTwoTone, DeleteTwoTone, CopyTwoTone, PlusCircleTwoTone } from '@ant-design/icons';
import { generateKey } from 'packages/utils';
import { buttonCommonClass } from './constants';
import { useIntl } from 'react-intl';
import { actionButton } from 'packages/utils/proptypes/components';
import classNames from 'classnames';

const InfoCardList = React.memo(
  ({
    avatar,
    title,
    subTitle,
    actionButtons = [],
    className,
    extraRightContent,
    extraRightClassName,
    type = 'primary',
  }) => {
    const ConfirmContext = createContext(null);
    const intl = useIntl();
    const [modal, contextHolder] = Modal.useModal();
    const hasActions = actionButtons?.length > 0;
    const config = c => ({
      title: c.title,
      cancelText: intl.formatMessage({ id: 'button.cancel' }),
      okText: intl.formatMessage({ id: 'button.yes' }),
      onOk: c.action,
    });

    const TOP_ICONS_BUTTONS = {
      edit: props => <EditTwoTone {...props} />,
      delete: props => <DeleteTwoTone {...props} twoToneColor="#E13E3B" />,
      clone: props => <CopyTwoTone {...props} />,
      add: props => <PlusCircleTwoTone {...props} />,
    };

    const AUX_CLASS_BY_BUTTON = {
      edit: styles.editIconButton,
      delete: styles.deleteIconButton,
      clone: styles.cloneIconButton,
      add: styles.addIconButton,
    };

    const styleType = {
      primary: styles.primary,
      secondary: styles.secondary,
    };

    return (
      <ConfirmContext.Provider value={{}}>
        <div className={classNames(styles.infoCardListContainer, styleType[type], className)}>
          <div className={styles.infoLeftContent}>
            {avatar && <div className="gx-pr-2 gx-align-self-center">{avatar}</div>}
            <div className={styles.infoLeftTitleAndSubtitle}>
              <span className="gx-guarnic-body">{title}</span>
              <span className="gx-guarnic-description">{subTitle}</span>
            </div>
          </div>

          <div className={styles.infoRightContent}>
            {extraRightContent && (
              <div className={extraRightClassName}>
                <div>{extraRightContent}</div>
              </div>
            )}
            {hasActions &&
              actionButtons?.length > 0 &&
              actionButtons?.map((bt, idx) => {
                if (bt?.hidden) return null;
                return (
                  <div key={generateKey(idx)}>
                    {bt?.allowedRole && bt?.allowedRole.length > 0 ? (
                      <ComponentBlocker divId={bt?.divId} allowedRoles={bt.allowedRole}>
                        <Button
                          {...buttonCommonClass({ props: bt })}
                          icon={
                            bt?.iconButton && bt?.iconButton === 'custom'
                              ? bt.customIcon
                              : TOP_ICONS_BUTTONS?.[bt?.iconButton]?.()
                          }
                          className={classNames(
                            bt?.iconButton && AUX_CLASS_BY_BUTTON[bt?.iconButton],
                            bt?.className,
                            'gx-mb-0',
                          )}
                          onClick={
                            bt?.confirm ? () => modal.confirm(config(bt.confirm)) : bt?.action
                          }
                        >
                          {bt?.label}
                        </Button>
                      </ComponentBlocker>
                    ) : (
                      <Button
                        {...buttonCommonClass({ props: bt })}
                        icon={
                          bt?.iconButton && bt?.iconButton === 'custom'
                            ? bt.customIcon
                            : TOP_ICONS_BUTTONS[bt?.iconButton]?.()
                        }
                        className={classNames(
                          bt?.iconButton && AUX_CLASS_BY_BUTTON[bt?.iconButton],
                          bt?.className,
                          'gx-mb-0',
                        )}
                        onClick={bt?.confirm ? () => modal.confirm(config(bt.confirm)) : bt?.action}
                      >
                        {bt?.label}
                      </Button>
                    )}
                  </div>
                );
              })}
          </div>

          {contextHolder}
        </div>
      </ConfirmContext.Provider>
    );
  },
);

InfoCardList.defaultProps = {
  avatar: null,
  title: null,
  subTitle: null,
  actionButtons: [],
  className: '',
  extraRightContent: null,
  extraRightClassName: '',
  type: 'primary',
};

InfoCardList.propTypes = {
  avatar: PropTypes.object,
  title: PropTypes.any,
  subTitle: PropTypes.any,
  actionButtons: PropTypes.oneOfType([PropTypes.arrayOf(actionButton), PropTypes.any]),
  type: PropTypes.oneOf(['primary', 'secondary']),
};

export { InfoCardList };
