import React from 'react';
import { Col, Input, Row, Select } from 'antd';
import Title from 'components/BoxContainer/components/Title';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { useIntl } from 'react-intl';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import { PERMISSIONS_BY_DEFAULT, USER_STATE_BY_DEFAULT } from 'packages/users/constants';

const UserFilters = React.memo(({ handleFilters, filters }) => {
  const intl = useIntl();

  const PERMISSIONS_OPTIONS = [
    {
      value: 'o',
      label: intl.formatMessage({ id: 'accessType.owner' }),
    },
    {
      value: 'a',
      label: intl.formatMessage({ id: 'accessType.admin' }),
    },
    {
      value: 'r',
      label: intl.formatMessage({ id: 'accessType.reader' }),
    },
    {
      value: 'u',
      label: intl.formatMessage({ id: 'accessType.user' }),
    },
  ];

  const USER_STATE_OPTIONS = [
    {
      value: true,
      label: intl.formatMessage({ id: 'general.enabled' }),
    },
    {
      value: false,
      label: intl.formatMessage({ id: 'general.disabled' }),
    },
  ];

  return (
    <Row justify="space-between" gutter={[16, 8]}>
      <Col xs={24} sm={8} md={8} xl={8} xxl={8}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="general.name" />} />}
          value={
            <Input
              className="gx-w-100"
              value={filters?.name || ''}
              placeholder={intl.formatMessage({ id: 'users.filter.user.name' })}
              onInput={e =>
                handleFilters(prev => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
            />
          }
        />
      </Col>
      <Col xs={24} sm={8} md={8} xl={8} xxl={8}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="users.filter.user.roles" />} />}
          value={
            <Select
              mode="multiple"
              options={PERMISSIONS_OPTIONS}
              defaultValue={PERMISSIONS_BY_DEFAULT}
              value={filters?.permissions}
              onChange={val =>
                handleFilters(prev => ({
                  ...prev,
                  permissions: val,
                }))
              }
              className="gx-w-100"
              maxTagCount={2}
            />
          }
        />
      </Col>
      <Col xs={24} sm={8} md={8} xl={8} xxl={8}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="users.filter.user.status" />} />}
          value={
            <Select
              options={USER_STATE_OPTIONS}
              defaultValue={USER_STATE_BY_DEFAULT}
              value={filters?.state}
              onChange={val =>
                handleFilters(prev => ({
                  ...prev,
                  state: val,
                }))
              }
              className="gx-w-100"
            />
          }
        />
      </Col>
    </Row>
  );
});

UserFilters.propTypes = {
  handleFilters: PropTypes.func.isRequired,
  filters: PropTypes.shape({
    name: PropTypes.string,
    permissions: PropTypes.array,
    state: PropTypes.bool,
  }).isRequired,
};

export default UserFilters;
