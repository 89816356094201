import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import IntlMessages from 'util/IntlMessages';
import { Drawer, Empty } from 'antd';
import { useSelector } from 'react-redux';
import { collection, query, where, orderBy } from 'firebase/firestore';
import { db } from 'firebase/firebase';
import { useFirestoreQueryBatched } from 'packages/utils';
import TimelineEvent from 'packages/events/components/TimelineEvent';

const TimelineByUser = ({ isVisible, onCancel, user }) => {
  const orgId = useSelector(state => state.organizations.organization.id);
  const divisionsPerUser = useMemo(() => Object.keys(user.divisions), [user.divisions]);

  const queryByDivId = divisionsPerUser.map(divId =>
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'events'),
      where('uid', '==', user?.id),
      orderBy('createdAtDevice', 'desc'),
    ),
  );
  const { data, loading } = useFirestoreQueryBatched(queryByDivId, [divisionsPerUser]);
  const sortedData = data.sort((a, b) => (a.createdAtDevice < b.createdAtDevice ? 1 : -1));
  return (
    <Drawer open={isVisible} onClose={() => onCancel(false)} closable={false} footer={null}>
      <BoxContainer>
        <BoxContainer content fixed>
          <FilterContainer
            goBack={() => onCancel(false)}
            title={
              <Title.Header
                value={<IntlMessages id="activeUserDashboard.timeline.drawer.title" />}
              />
            }
          />
        </BoxContainer>
        <BoxContainer content loading={loading}>
          {sortedData.length > 0 ? (
            sortedData.map(evt => <TimelineEvent event={evt} key={evt.id} />)
          ) : (
            <Empty description={<IntlMessages id="general.empty.state" />} />
          )}
        </BoxContainer>
      </BoxContainer>
    </Drawer>
  );
};

TimelineByUser.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
};

export default TimelineByUser;
