import React, { useCallback, useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import { DatePicker, Form, Input, Select, notification, Row, Col, Button, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, useRouteMatch, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';

import { CaretDownOutlined } from '@ant-design/icons';
import IntlMessages from 'util/IntlMessages';
import * as schedulesActions from 'packages/schedules/redux/actions';
import { db, Timestamp } from 'firebase/firebase';
import { collection, doc, getDoc } from 'firebase/firestore';
import {
  getDocData,
  ALLOWED_ROLES,
  getDivsWithAccess,
  getFullUrl,
  getUserTimeZoneDate,
  useToggle,
} from 'packages/utils';

import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import { UsersGroupsWidget } from 'components/Users';
import MembersListDetail from 'components/MemberList/MembersListDetail';
import { EventTypeList } from 'packages/schedules/components/Event';
// eslint-disable-next-line import/no-extraneous-dependencies
import { getSchedule as getScheduleCron, stringToArray } from 'cron-converter';
import ConfirmUpdateSchedule from 'packages/schedules/components/ConfirmUpdateSchedule';
import { SCHEDULE_TYPES } from 'constants/commons';
import { EventType } from 'components/Events';
import { SortableComponent } from 'components/Sortable/SortableComponent';
import { createScheduleOptions } from 'util/firebase-operations/schedule/create';
import DurationPicker from '../../components/DurationPicker';
import MembersForm from '../../components/MembersForm';
import RepetitionSelection from '../../components/RepetitionSelector';
import TooltipLabel from '../../components/TooltipLabel';
import TriggersForm from '../../components/TriggersForm';
import {
  ACTION_SCHEDULE_OPTIONS,
  FORM_ITEM_LAYOUT,
  FORMS,
  PATHS,
  triggersFactory,
} from '../../constants';
import styles from './styles.module.less';

const { Item } = Form;

const { Option } = Select;

const MAX_TRIGGERS = 20;

const getDefaultMembers = () => ({
  groups: [],
  users: [],
});

const Schedule = () => {
  const executionOverrideOptionsRef = useRef({
    action: ACTION_SCHEDULE_OPTIONS.next,
    scheduleRequest: null,
  }); // NOTE: Has 3 states (NOW, NEXT or NULL), null means no action.
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const { divId, schId } = useParams();
  const { url } = useRouteMatch();
  const now = moment();

  // For sync with the secondary forms
  const [triggers, setTriggers] = useState([]);
  const [triggerEdit, setTriggerEdit] = useState(null);
  const [triggersFormVisible, setTriggersFormVisible] = useState(false);
  const [membersFormVisible, setMembersFormVisible] = useState(false);
  const [members, setMembers] = useState(getDefaultMembers());

  const [isRecurrent, setIsRecurrent] = useState(false);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [newDivId, setNewDivId] = useState(null);
  const [readyToClose, setReadyToClose] = useState(false);
  const [processing, setIsProccessing] = useState(false);
  const [taskOrderRequired, setTaskOrderRequired] = useState(false);

  const [confirmExecutionVisible, onConfirmExecutionVisible] = useToggle(false);

  // startDate + startTime
  const [startAt, setStartAt] = useState(now.clone());

  const { id: orgId } = useSelector(({ organizations }) => organizations.organization);
  const profile = useSelector(({ user }) => user.profile);
  const loadedSchedules = useSelector(({ schedules }) => schedules.schedule.save.loaded);

  const isEditing = !!schId;
  const loading = isEditing && !currentSchedule;

  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const { userOrgDivisions } = useSelector(
    ({
      divisions: {
        assigned: { data },
      },
    }) => ({
      userOrgDivisions: data.divisions,
    }),
  );
  const triggerIdsSelected = triggers?.map(item => item?.triggerId).filter(item => Boolean(item));

  const hasExecutionActived = currentSchedule?.currentExecutionId !== undefined;
  const onChangeExecutionOverride = e => {
    const { value } = e.target;
    executionOverrideOptionsRef.current.action = value;
  };

  const allowedDivisions = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.CREATE,
    userOrgAccess,
    userOrgDivisions,
  );

  // Get "isToClone" value from state(useLocation) to identify the button action previously triggered
  const { state } = useLocation();
  const isToClone = state?.isToClone ?? false;
  // NOTE: getStartAt from firebase and setting timezone from schedule to show real date selected.
  const getStartAt = useCallback(
    sch =>
      isToClone ? moment() : moment(sch.schedule.startAt.toMillis()).tz(sch.schedule.timeZone),
    [isToClone],
  );

  useEffect(() => {
    if (isEditing) {
      const getSchedule = async () => {
        const sch = getDocData(
          await getDoc(doc(db, 'organizations', orgId, 'divisions', divId, 'schedules', schId)),
        );

        setMembers(sch.members);
        setMembersFormVisible(false);
        setStartAt(() => getStartAt(sch));
        setTriggerEdit(null);
        setTriggers(sch.triggers);
        setTriggersFormVisible(false);
        setCurrentSchedule(sch);
        setTaskOrderRequired(sch?.triggersFollowIndex || false);
      };

      getSchedule();
    }
  }, [divId, orgId, schId, isEditing, getStartAt, form, profile.data?.timeZone]);

  const handleCancelTriggers = () => {
    setTriggerEdit(null);
    setTriggersFormVisible(false);
  };

  const handleCancelMembers = () => setMembersFormVisible(false);

  const handleGoBack = useCallback(
    () => history.push(getFullUrl(PATHS.BASE_URL, url)),
    [history, url],
  );

  const getEndAt = (endDate, duration) => {
    const nextDay = startAt.clone().add(1, 'day').startOf('day');

    // If the schedule does not repeat then "endDate" is undefined.
    const isOneTimeExecution = !endDate;
    if (isOneTimeExecution) {
      // If the schedule does not repeat then the schedule "endAt" should be the (startAt + duration)
      return startAt.clone().add(duration, 'minutes');
    }

    return nextDay.isAfter(endDate) ? nextDay : endDate.clone().startOf('day');
  };

  const onFormFinish = async (name, { values, forms }) => {
    // NOTE: "showWarning" is to control when show the confirm modal to override the execution
    const showWarning = isEditing && hasExecutionActived && !isToClone;
    try {
      const divPerSchedule = divId || values.divId;

      if (name === FORMS.MEMBERS) {
        setMembers(forms[FORMS.MEMBERS].getFieldsValue(['groups', 'users']));
        setMembersFormVisible(false);
      }

      if (name === FORMS.TRIGGERS) {
        const locations = forms[FORMS.TRIGGERS].getFieldValue('locations');
        const cooldown = values.cooldown.hours() * 60 + values.cooldown.minutes();
        const triggerId = values?.triggerId || '';

        const newTrigger = {
          ...values,
          locations,
          cooldown,
        };

        if (triggerEdit) {
          const updatedTrigger = triggerId
            ? { ...newTrigger, id: triggerEdit.id, triggerId }
            : { ...newTrigger, id: triggerEdit.id }; // Check if contain a trigger from triggers collection;
          const updatedTriggers = triggers.filter(t => t.id !== updatedTrigger.id);
          updatedTriggers.push(updatedTrigger);
          setTriggers(updatedTriggers);
          setTriggerEdit(null);
        } else {
          // This is not a real collection, im just using FB to create a unique id
          const newDocRef = doc(collection(db, 'triggers'));
          const triggerDocId = triggerId ? { id: newDocRef.id, triggerId } : { id: newDocRef.id }; // Check if contain a trigger from triggers collection;
          const updatedTriggers = [...triggers, { ...newTrigger, ...triggerDocId }];
          setTriggers(updatedTriggers);
        }
        setTriggersFormVisible(false);
      }

      if (name === FORMS.SCHEDULE) {
        setIsProccessing(true);

        const endAt = getEndAt(values.endDate, values.duration);

        const reminders = [];
        if (+values.reminder) {
          reminders.push(+values.reminder);
        }

        // NOTE: set timezone selected;
        const startAtTz = moment(startAt).tz(values.timeZone, true).seconds(0);
        const endAtTz = moment(endAt).tz(values.timeZone, true).seconds(0);

        const schedule = {
          divId: divPerSchedule,
          duration: values.duration,
          members,
          name: values.name,
          reminders,

          schedule: {
            startAt: Timestamp.fromMillis(startAtTz.valueOf()),
            endAt: Timestamp.fromMillis(endAtTz.valueOf()), // UTC
            repetition: values.repetition,
            timeZone: values.timeZone,
          },
          triggers,
          type: SCHEDULE_TYPES.schedule,
          triggersFollowIndex: values?.triggersFollowIndex || false,
        };
        // If is to clone we going to clear the schedule.id to create a new record
        if (isToClone) {
          schedule.id = '';
          // Create new triggers Ids because of is a clone but no exactly the same..
          schedule.triggers = schedule.triggers.map(trigger => {
            const newTrigger = trigger;
            const newDocRef = doc(collection(db, 'triggers'));
            newTrigger.id = newDocRef.id;
            return newTrigger;
          });
        } else if (schId) {
          schedule.id = schId;
        }

        // Manage new schema of eventTypeSummary
        const triggerPerSchedule = schedule.triggers;
        let newTriggers = [];
        await Promise.all(
          triggerPerSchedule.map(async trigger => {
            const eventTypeId = trigger.eventType || null;

            if (eventTypeId && typeof eventTypeId === 'string') {
              const eventData = await getDoc(
                doc(
                  db,
                  'organizations',
                  orgId,
                  'divisions',
                  divPerSchedule,
                  'event_types',
                  eventTypeId,
                ),
              ).then(snapshot => snapshot.data());

              if (!eventData) {
                throw new Error(`Event Type doesn't exits`);
              }

              // remove unnecessary fields
              const { uid, createdAt, updatedAt, ...eventDataToTrigger } = eventData;

              newTriggers.push({
                ...trigger,
                eventType: eventDataToTrigger,
              });
            } else {
              // We don't change anything due to already exists like a new schema
              newTriggers.push(trigger);
            }
          }),
        );

        newTriggers = triggersFactory(newTriggers, values?.triggersFollowIndex);

        schedule.triggers = newTriggers;
        if (showWarning) {
          onConfirmExecutionVisible();
          executionOverrideOptionsRef.current.scheduleRequest = {
            orgId,
            schedule,
            divId: divId || values.divId,
          };
        } else {
          dispatch(schedulesActions.scheduleSaveFetch(orgId, divId || values.divId, schedule));
          setReadyToClose(true);
          executionOverrideOptionsRef.current.action = null;
        }
      }
    } catch (error) {
      notification.error({
        message: intl.formatMessage({ id: 'general.save.error.message' }),
        placement: 'topRight',
      });
      setIsProccessing(false);
    }
  };

  const removeTrigger = id => {
    const update = triggers.filter(t => t.id !== id);
    setTriggers(update);
  };

  const editTrigger = trigger => {
    setTriggerEdit(trigger);
    setTriggersFormVisible(true);
  };

  const onRemoveMember = userId => {
    const memberToBeRemoved = [...members.groups, ...members.users];
    const usersFiltered = memberToBeRemoved.filter(usr => usr.id !== userId);

    const usersAndGroups = usersFiltered.reduce(
      (acc, val) => {
        // eslint-disable-next-line no-prototype-builtins
        const key = val.hasOwnProperty('users') ? 'groups' : 'users';
        acc[key].push(val);
        return acc;
      },
      { users: [], groups: [] },
    );

    setMembers(usersAndGroups);
  };

  const disableByBeOutOfRange = React.useMemo(() => {
    if (!currentSchedule?.jobs) return false;

    const {
      jobs: { start: futureStart },
      schedule: { endAt },
    } = currentSchedule;
    // NOTE: we are going to remove "?" since the library does not accept that format and remove seconds as well
    const futureStartParsed = futureStart.cron.replace(/\?/g, '*').slice(1);
    const arr = stringToArray(futureStartParsed);
    const schedule = getScheduleCron(arr);
    const nextExecution = schedule.next().toJSDate();
    return moment(nextExecution).isAfter(moment(endAt.toDate()));
  }, [currentSchedule]);

  const getStartTimeByTimeZone = useCallback(() => {
    if (!currentSchedule?.schedule || isToClone) {
      return startAt.local().hours() * 60 + startAt.local().minutes();
    }

    const dte = getStartAt(currentSchedule);

    return dte.minutes() + dte.hours() * 60;
  }, [currentSchedule, getStartAt, isToClone, startAt]);

  const getRecurrenceByTimeZone = useCallback(() => {
    if (!currentSchedule || isToClone) {
      return startAt.local();
    }
    // NOTE: invert timezones to get the timeZone selected from FIREBASE
    const dateWithTimeZone = getUserTimeZoneDate(
      startAt.valueOf(),
      profile.data?.timeZone,
      currentSchedule.schedule.timeZone,
    ).jsDate;
    // NOTE: I need to do that to get real timezone.
    return moment(new Date(dateWithTimeZone));
  }, [currentSchedule, isToClone, profile.data?.timeZone, startAt]);

  // NOTE: Due to orderBy "createdAt" into ScheduleView we need handle async the CRUD
  useEffect(() => {
    if (loadedSchedules && readyToClose) {
      // NOTE: if the schedule it's in condition to be updated then we will insert into /schedule/options;
      if (executionOverrideOptionsRef?.current?.action !== null) {
        const addScheduleOptions = async () => {
          try {
            await createScheduleOptions({
              data: { action: executionOverrideOptionsRef?.current?.action },
              orgId,
              divId: divId || newDivId,
              scheduleId: schId,
            });
          } catch (error) {
            notification.error({
              message: intl.formatMessage({ id: 'schedule.update.execution.error' }),
              placement: 'topRight',
            });
          }
        };
        addScheduleOptions();
      }
      notification.success({
        message: intl.formatMessage({ id: 'general.save.successful.message' }),
      });
      handleGoBack();
    }
  }, [loadedSchedules, readyToClose, handleGoBack, orgId, divId, newDivId, schId, intl]);

  return (
    <BoxContainer>
      <BoxContainer content fixed shadow>
        <FilterContainer
          goBack={handleGoBack}
          title={<Title.Header value={<IntlMessages id="schedule.form.create.label" />} />}
          actionButtons={[
            {
              label: <IntlMessages id="form.save" />,
              type: 'primary',
              action: form.submit,
              disabled: loading || processing,
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer content loading={loading || processing}>
        {!loading && (
          <Form.Provider onFormFinish={onFormFinish}>
            <Form
              form={form}
              name={FORMS.SCHEDULE}
              initialValues={{
                duration: currentSchedule?.duration || 60,
                // "endDate" will be null on formFinish's value if "repetition" is "none"
                endDate: currentSchedule
                  ? moment(currentSchedule.schedule.endAt.toMillis())
                  : now.clone().add(1, 'day').startOf('day'),
                startDate: startAt,
                name: isToClone ? '' : currentSchedule?.name || '',
                reminder: currentSchedule?.reminders?.[0] || 0,
                repetition: currentSchedule?.schedule?.repetition,
                startTime: getStartTimeByTimeZone(),
                timeZone: currentSchedule?.schedule?.timeZone || profile.data?.timeZone,
                triggersFollowIndex: currentSchedule?.triggersFollowIndex || false,
              }}
              {...FORM_ITEM_LAYOUT}
            >
              <Row gutter={[8, 8]}>
                {!divId && (
                  <Col xs={24} xl={12} xxl={12}>
                    <>
                      <Title.LabelForm
                        required
                        className="gx-guarnic-pb-1"
                        value={intl.formatMessage({ id: 'schedule.form.divId.label' })}
                      />

                      <Item
                        name="divId"
                        required
                        rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id: 'schedule.form.divId.requiredError',
                            }),
                          },
                        ]}
                      >
                        <Select
                          getPopupContainer={trigger => trigger.parentNode}
                          dropdownMatchSelectWidth={false}
                          onChange={id => {
                            setNewDivId(id);
                            setMembers(getDefaultMembers());
                          }}
                          optionFilterProp="children"
                          placeholder={<IntlMessages id="divisionSelector.placeholder" />}
                          showSearch
                        >
                          {allowedDivisions.map(div => (
                            <Option key={div.id} value={div.id}>
                              {div.name}
                            </Option>
                          ))}
                        </Select>
                      </Item>
                    </>
                  </Col>
                )}
                <Col xs={24} xl={divId ? 24 : 12} xxl={divId ? 24 : 12}>
                  <Title.LabelForm
                    required
                    className="gx-guarnic-pb-1"
                    value={intl.formatMessage({ id: 'schedule.form.name.label' })}
                  />
                  <Item
                    name="name"
                    required
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'schedule.form.name.requiredError' }),
                      },
                      {
                        min: 5,
                        message: intl.formatMessage({ id: 'form.minLength.msg' }, { amount: 5 }),
                      },
                      {
                        max: 180,
                        message: intl.formatMessage({ id: 'form.maxLength.msg' }, { amount: 180 }),
                      },
                    ]}
                  >
                    <Input
                      placeholder={intl.formatMessage({ id: 'schedule.form.name.placeholder' })}
                    />
                  </Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col span={24} className="gx-pt-2 gx-guarnic-pb-2">
                  <span className="gx-guarnic-label">
                    <IntlMessages id="schedule.form.sectionTitle.whenShouldOccur" />
                  </span>
                </Col>
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    required
                    value={<IntlMessages id="schedule.form.date.label" />}
                    className="gx-guarnic-pb-1"
                  />
                  <Item
                    name="startDate"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({ id: 'schedule.form.date.requiredError' }),
                      },
                    ]}
                  >
                    <DatePicker
                      getPopupContainer={trigger => trigger.parentNode}
                      allowClear={false}
                      className={styles.fullWidth}
                      onChange={v => {
                        setStartAt(
                          v.clone().startOf('day').add(form.getFieldValue('startTime'), 'minutes'),
                        );
                      }}
                      disabledDate={current =>
                        current && current < moment(now.format('YYYY-MM-DD'), 'YYYY-MM-DD')
                      }
                      // inputReadOnly avoids virtual keyboard on touch devices
                      inputReadOnly
                    />
                  </Item>
                </Col>
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    required
                    value={<IntlMessages id="schedule.form.time.label" />}
                    className="gx-guarnic-pb-1"
                  />
                  <Item name="startTime" required>
                    <DurationPicker
                      getPopupContainer={trigger => trigger.parentNode}
                      className={styles.fullWidth}
                      minuteStep={1}
                      onChange={v => {
                        setStartAt(
                          form.getFieldValue('startDate').clone().startOf('day').add(v, 'minutes'),
                        );
                      }}
                    />
                  </Item>
                </Col>
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    required
                    value={<IntlMessages id="schedule.form.duration.label" />}
                    className="gx-guarnic-pb-1"
                  />
                  <Item
                    name="duration"
                    required
                    rules={[
                      {
                        validator: async (rule, value) => {
                          if (value < 15) {
                            return Promise.reject(
                              intl.formatMessage({ id: 'schedule.form.duration.requiredError' }),
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                    className={styles.tooltipLabel}
                  >
                    <DurationPicker
                      getPopupContainer={trigger => trigger.parentNode}
                      className={styles.fullWidth}
                      placeholder={intl.formatMessage({ id: 'schedule.form.duration.placeholder' })}
                    />
                  </Item>
                </Col>
                <Col xs={24} xl={12} xxl={12}>
                  <Title.LabelForm
                    required
                    value={<IntlMessages id="schedule.form.timeZone.label" />}
                    className="gx-guarnic-pb-1"
                  />
                  <Item name="timeZone" required>
                    <Select
                      getPopupContainer={trigger => trigger.parentNode}
                      id="timeZone"
                      showSearch
                    >
                      {moment.tz.names().map(tz => (
                        <Select.Option key={tz} value={tz}>
                          {tz} - <em>GMT {moment.tz(tz).format('Z')}</em>
                        </Select.Option>
                      ))}
                    </Select>
                  </Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col xs={24} xl={10} xxl={10}>
                  <Title.LabelForm
                    className="gx-guarnic-pb-1"
                    value={
                      <TooltipLabel
                        label={intl.formatMessage({ id: 'schedule.form.repetition.label' })}
                        tooltip={intl.formatMessage({ id: 'schedule.form.repetition.tooltip' })}
                      />
                    }
                  />
                  <Item className={styles.tooltipLabel} name="repetition">
                    <RepetitionSelection
                      setIsRecurrent={setIsRecurrent}
                      className={styles.selectInput}
                      initialDate={getRecurrenceByTimeZone()}
                      suffixIcon={<CaretDownOutlined className={styles.selectIcon} />}
                      disabled={disableByBeOutOfRange}
                    />
                  </Item>
                </Col>
              </Row>

              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Title.LabelForm
                    className="gx-guarnic-pb-1"
                    value={
                      <TooltipLabel
                        label={intl.formatMessage({ id: 'schedule.form.reminder.label' })}
                        tooltip={intl.formatMessage({ id: 'schedule.form.reminder.tooltip' })}
                      />
                    }
                  />
                  <Item className={styles.tooltipLabel} name="reminder">
                    <Select
                      className={styles.selectInput}
                      suffixIcon={<CaretDownOutlined className={styles.selectIcon} />}
                    >
                      <Option value={0}>
                        <IntlMessages id="schedule.reminder.none" />
                      </Option>
                      <Option value={5}>
                        <IntlMessages id="schedule.reminder.minutes" values={{ amount: 5 }} />
                      </Option>
                      <Option value={15}>
                        <IntlMessages id="schedule.reminder.minutes" values={{ amount: 15 }} />
                      </Option>
                      <Option value={30}>
                        <IntlMessages id="schedule.reminder.minutes" values={{ amount: 30 }} />
                      </Option>
                      <Option value={60}>
                        <IntlMessages id="schedule.reminder.hours" values={{ amount: 1 }} />
                      </Option>
                      <Option value={120}>
                        <IntlMessages id="schedule.reminder.hours" values={{ amount: 2 }} />
                      </Option>
                    </Select>
                  </Item>
                </Col>
                {isRecurrent && (
                  <Col span={24}>
                    <Title.LabelForm
                      className="gx-guarnic-pb-1"
                      value={
                        <TooltipLabel
                          label={intl.formatMessage({ id: 'schedule.form.endDate.label' })}
                          tooltip={intl.formatMessage({ id: 'schedule.form.endDate.tooltip' })}
                        />
                      }
                    />
                    <Item className={styles.tooltipLabel} name="endDate" required>
                      <DatePicker
                        getPopupContainer={trigger => trigger.parentNode}
                        allowClear={false}
                        className={styles.fullWidth}
                        disabledDate={current => current.isBefore(startAt)}
                        // inputReadOnly avoids virtual keyboard on touch devices
                        inputReadOnly
                      />
                    </Item>
                  </Col>
                )}
              </Row>

              {(divId || newDivId) && (
                <Row gutter={[8, 8]}>
                  <Col span={24}>
                    <div className="gx-flex-row gx-justify-content-between gx-guarnic-pb-1">
                      <div className="gx-pt-2">
                        <Title.LabelForm
                          required
                          value={
                            <span className="gx-guarnic-label">
                              <IntlMessages id="schedule.form.sectionTitle.whoShouldReceive" />
                            </span>
                          }
                        />
                      </div>
                      <div className={styles.usersGroupsContainer}>
                        <UsersGroupsWidget
                          groups={members.groups.length}
                          users={members.users.length}
                        />
                      </div>
                    </div>
                    <Item
                      name="members"
                      required
                      rules={[
                        {
                          validator: async () => {
                            const total = members.groups.length + members.users.length;
                            if (total < 1) {
                              return Promise.reject(
                                intl.formatMessage({
                                  id: 'schedule.form.members.noMembersAssigned',
                                }),
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <MembersListDetail
                        members={[...members.users, ...members.groups]}
                        onRemove={onRemoveMember}
                      />
                      <Button
                        className="gx-mb-0 gx-guarnic-mt-2"
                        type="primary"
                        block
                        onClick={() => setMembersFormVisible(true)}
                      >
                        <IntlMessages id="button.add" />
                      </Button>
                    </Item>
                  </Col>

                  <Col span={24}>
                    <div className={styles.eventsTitleContainer}>
                      <Title.LabelForm
                        required
                        value={
                          <span className="gx-guarnic-label">
                            <IntlMessages
                              id="schedule.form.sectionTitle.whatEventsShouldInclude"
                              values={{ amount: MAX_TRIGGERS }}
                            />
                          </span>
                        }
                      />
                      <div className="gx-flex-row">
                        <Title.LabelForm
                          value={<IntlMessages id="work.template.form.task.order.required" />}
                          className="gx-flex-column gx-justify-content-center gx-pr-2"
                        />
                        <Item
                          className="gx-mb-0"
                          name="triggersFollowIndex"
                          valuePropName="checked"
                        >
                          <Switch onChange={val => setTaskOrderRequired(val)} />
                        </Item>
                      </div>
                    </div>
                    <Item
                      name="triggers"
                      required
                      rules={[
                        {
                          validator: async () => {
                            if (triggers.length < 1) {
                              return Promise.reject(
                                intl.formatMessage({
                                  id: 'schedule.form.triggers.noTriggersAssigned',
                                }),
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <>
                        {taskOrderRequired ? (
                          <SortableComponent
                            items={triggers}
                            Component={EventType}
                            extraProps={[
                              { name: 'onEdit', action: editTrigger },
                              { name: 'onRemove', action: removeTrigger, args: ['id'] },
                            ]}
                            className="gx-guarnic-pb-2"
                            onMove={setTriggers}
                            withBackground
                          />
                        ) : (
                          <EventTypeList
                            triggers={triggers}
                            withBackground
                            onEdit={editTrigger}
                            onRemove={removeTrigger}
                          />
                        )}

                        <Button
                          className="gx-mb-0 gx-guarnic-mt-2"
                          type="primary"
                          block
                          onClick={() =>
                            triggers.length < MAX_TRIGGERS ? setTriggersFormVisible(true) : null
                          }
                        >
                          <IntlMessages id="button.add" />
                        </Button>
                      </>
                    </Item>
                  </Col>
                </Row>
              )}
            </Form>

            {(divId || newDivId) && membersFormVisible && (
              <MembersForm
                defaultMembers={members || currentSchedule?.members}
                divId={divId || newDivId}
                isVisible={membersFormVisible}
                onCancel={handleCancelMembers}
              />
            )}
            {(divId || newDivId) && (
              <TriggersForm
                divId={divId || newDivId}
                initialData={triggerEdit}
                isVisible={triggersFormVisible}
                onCancel={handleCancelTriggers}
                handleInitData={setTriggerEdit}
                triggerIdsSelected={triggerIdsSelected || []}
              />
            )}
          </Form.Provider>
        )}
      </BoxContainer>

      {confirmExecutionVisible && (
        <ConfirmUpdateSchedule
          isVisible={confirmExecutionVisible}
          onClose={() => {
            onConfirmExecutionVisible();
            setIsProccessing(false);
            executionOverrideOptionsRef.current.scheduleRequest = null;
          }}
          onChange={onChangeExecutionOverride}
          onSave={() => {
            dispatch(
              schedulesActions.scheduleSaveFetch(
                executionOverrideOptionsRef.current.scheduleRequest.orgId,
                executionOverrideOptionsRef.current.scheduleRequest.divId,
                executionOverrideOptionsRef.current.scheduleRequest.schedule,
              ),
            );
            setReadyToClose(true);
          }}
        />
      )}
    </BoxContainer>
  );
};

export default Schedule;
