import React from 'react';
import PropTypes from 'prop-types';
import IntlMessages from 'util/IntlMessages';
import { Badge } from 'antd';
import styles from './styles.module.less';
import classNames from 'classnames';

const ConnectionStatus = ({ connected, className }) => {
  return (
    <div className={classNames('gx-flex-row', className)}>
      <span
        className={classNames(
          'material-icons-outlined',
          styles.wifiIcon,
          'gx-align-content-center',
        )}
      >
        wifi
      </span>
      <div className="gx-flex-row gx-align-content-center gx-pl-1 gx-pr-1">
        <IntlMessages id="common.connected" />
      </div>
      <div className="gx-flex-col gx-align-content-center gx-pl-1 gx-pr-1">
        <Badge className="gx-m-0" status={connected ? 'success' : 'error'} />
      </div>
    </div>
  );
};

ConnectionStatus.defaultProps = {
  connected: false,
  className: null,
};

ConnectionStatus.propTypes = {
  connected: PropTypes.bool,
  className: PropTypes.string,
};

export default ConnectionStatus;
