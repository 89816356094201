const BASE_URL = 'dashboard';

export const PATHS = {
  BASE_URL,
  SCHEDULES: `${BASE_URL}/schedules`,
  TIMELINE_EVENT_DRAWER: `${BASE_URL}/schedules?&divId=:divId&triggerId=:triggerId&scheduleId=:scheduleId&gte=:gte&lte=:lte/events`,
  EVENT_TYPES_DRAWER: `${BASE_URL}/schedules?divId=:divId&gte=:gte&lte=:lte&interval=:interval&scheduleId=:scheduleId/event_types`,
  ACTIVE_SCHEDULES: `${BASE_URL}/active_schedules`,
  EVENTS: `${BASE_URL}/events`,
  EDIT_CHART: `${BASE_URL}/edit`,
  ACTIVE_USER_DASHBOARD: `${BASE_URL}/active_user`,
};

export const SELECT_PAGES_OPTIONS = [
  {
    label: 'All',
    value: 0,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 50,
    value: 50,
  },
];

export const getRouteToTimelineEventDrawer = (
  orgId = null,
  divId = null,
  triggerId = null,
  scheduleId = null,
  gte = null,
  lte = null,
) =>
  PATHS.TIMELINE_EVENT_DRAWER.replace(':orgId', orgId)
    .replace(':divId', divId)
    .replace(':triggerId', triggerId)
    .replace(':scheduleId', scheduleId)
    .replace(':gte', gte)
    .replace(':lte', lte);

export const getRouteToEventTypesDrawer = (divId, gte, lte, interval, scheduleId) =>
  PATHS.EVENT_TYPES_DRAWER.replace(':divId', divId)
    .replace(':gte', gte)
    .replace(':lte', lte)
    .replace(':interval', interval)
    .replace(':scheduleId', scheduleId);

export const getRouteToDashboardSchedules = () => PATHS.SCHEDULES;
