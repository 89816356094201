import PropTypes from 'prop-types';

export const circleButtonPropType = PropTypes.shape({
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  withBackground: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  hidden: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(['primary', 'danger']),
  iconNameText: PropTypes.string,
});
