/* eslint-disable no-shadow */
import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';
import { Col, DatePicker, Row, Segmented } from 'antd';

import IntlMessages from 'util/IntlMessages';
import { get, set } from 'packages/utils/storage';
import { KeyValueWidget } from 'components/KeyValueWidget';
import RelativeAbsolute from 'components/RelativeAbsolute';
import Title from 'components/BoxContainer/components/Title';
import {
  POSITION_HISTORY_STATE,
  POST_HOURS,
  POST_HOURS_BY_DEFAULT,
} from 'packages/reports/constants';

import styles from './styles.module.less';

const { RangePicker } = DatePicker;

const UserPositionFilters = ({ values, onChange, loading }) => {
  const postHoursOption = Object.keys(POST_HOURS).map(item => ({
    label: POST_HOURS[item],
    value: item,
  }));

  /*
    Block future dates
   */
  const disabledDate = current => {
    if (!current) return false;
    // block future dates
    const futureDates = current && current > moment().endOf('day');
    return futureDates;
  };
  const disabledRangeTime = (_, type) => {
    if (type === 'end') {
      const from = values?.absoluteDate?.[0];

      const hourSelected = from?.hour() + 1;
      const minutesSelected = from?.minute() + 1;
      const endHour = 24;
      const endMinutes = 60;

      // Here the way to block the hours and minutes is returning an array with the values to block;
      return {
        disabledHours: () =>
          Array.from({ length: endHour - hourSelected }, (_, i) => hourSelected + i),
        disabledMinutes: () =>
          Array.from({ length: endMinutes - minutesSelected }, (_, i) => minutesSelected + i),
      };
    }
    return {};
  };

  const onCalendarChange = e => {
    onChange(prev => ({
      ...prev,
      absoluteDate: [e?.[0], e?.[1]],
    }));
    set(POSITION_HISTORY_STATE, {
      ...get(POSITION_HISTORY_STATE),
      absoluteDate: [e?.[0], e?.[1]],
    });
  };

  return (
    <Row justify="start" gutter={[8, 8]}>
      <Col xs={24} sm={4} md={4} xl={4} xxl={4}>
        <KeyValueWidget
          label={<Title.Filter value={<IntlMessages id="general.filters" />} />}
          value={
            <RelativeAbsolute
              disabled={loading}
              defaultValue={values?.isRelative}
              onChange={value => {
                onChange(prev => ({
                  ...prev,
                  isRelative: value?.target?.value,
                }));
                set(POSITION_HISTORY_STATE, {
                  ...get(POSITION_HISTORY_STATE),
                  isRelative: value?.target?.value,
                });
              }}
            />
          }
        />
      </Col>
      {values.isRelative ? (
        <>
          <Col xs={24} sm={4} md={4} xl={4} xxl={4}>
            <KeyValueWidget
              label={
                <Title.Filter
                  value={<IntlMessages id="reports.userPositionHistory.day.filter" />}
                />
              }
              value={
                <DatePicker
                  onChange={d => {
                    onChange(prev => ({
                      ...prev,
                      date: d,
                    }));
                    // set localStorage
                    set(POSITION_HISTORY_STATE, {
                      ...get(POSITION_HISTORY_STATE),
                      date: d,
                    });
                  }}
                  format="YYYY-MM-DD HH:mm"
                  value={values.date}
                  className="gx-w-100"
                  showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
                  showNow={false}
                  clearIcon={false}
                  disabled={loading}
                />
              }
            />
          </Col>
          <Col xs={24} sm={5} md={5} xl={5} xxl={5}>
            <KeyValueWidget
              label={
                <Title.Filter
                  value={<IntlMessages id="reports.userPositionHistory.hours.filter" />}
                />
              }
              value={
                <Segmented
                  className={styles.hourPicker}
                  value={values.hours}
                  onChange={e => {
                    onChange(prev => ({
                      ...prev,
                      hours: e,
                    }));
                    set(POSITION_HISTORY_STATE, {
                      ...get(POSITION_HISTORY_STATE),
                      hours: e,
                    });
                  }}
                  defaultValue={POST_HOURS_BY_DEFAULT}
                  options={postHoursOption}
                  disabled={loading}
                />
              }
            />
          </Col>
        </>
      ) : (
        <Col xs={24} sm={6} md={6} xl={6} xxl={6}>
          <KeyValueWidget
            label={<Title.Filter value={<IntlMessages id="general.from.to" />} />}
            value={
              <RangePicker
                disabledDate={disabledDate}
                disabledTime={disabledRangeTime}
                className="gx-w-100"
                showTime={{
                  format: 'HH:mm',
                }}
                format="YYYY-MM-DD HH:mm"
                value={values?.absoluteDate}
                onCalendarChange={onCalendarChange}
                onChange={onCalendarChange}
                disabled={loading}
              />
            }
          />
        </Col>
      )}
    </Row>
  );
};

UserPositionFilters.propTypes = {
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    date: PropTypes.instanceOf(moment),
    hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isRelative: PropTypes.bool,
    absoluteDate: PropTypes.arrayOf(PropTypes.instanceOf(moment)),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default UserPositionFilters;
