import React, { useState } from 'react';
import styles from './styles.module.less';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import { ChildrenSchema, useIsMobile } from 'packages/utils';
import classNames from 'classnames';
import IntlMessages from 'util/IntlMessages';
import { generateKey } from '../../packages/utils/functions';
import ComponentBlocker from 'components/ComponentBlocker';
import { LeftOutlined } from '@ant-design/icons';
import { actionButton, circleButtonPropType } from 'packages/utils/proptypes/components';
import CircleButton from 'components/Buttons/CircleButton';

const FilterContainer = ({
  actionButtons,
  title,
  goBack,
  content,
  showHide,
  description,
  buttonItems,
}) => {
  const { isMobile } = useIsMobile();
  const [show, setShow] = useState(true);
  const showHideButtonLabel = show ? 'button.hide' : 'button.show';
  const showHideClass = show ? styles.visible : styles.hidden;
  const hasActionButton = actionButtons.length > 0;
  const contentClass = show ? classNames(styles.boxB, showHideClass) : showHideClass;

  const getSizeButton = size => {
    if (isMobile) return 'small';

    return size || 'middle';
  };

  return (
    <div>
      <div className={styles.container}>
        <div className={classNames(styles.body, !showHide && !description && 'gx-pb-2')}>
          <div className={styles.filterContainer}>
            <div className={classNames(styles.boxA, !hasActionButton && 'gx-guarnic-pb-1')}>
              <div className={styles.titleContainer}>
                {goBack && (
                  <div className={styles.leftIcon}>
                    <Button
                      className="gx-mb-2"
                      onClick={goBack}
                      shape="circle"
                      icon={<LeftOutlined className="gx-text-black" />}
                      size="middle"
                    />
                  </div>
                )}
                {title && <div className={classNames(styles.titleChildren)}>{title}</div>}
              </div>
              {buttonItems.length > 0 && (
                <div
                  className={classNames(styles.buttonsContainer, 'gx-d-flex', 'gx-guarnic-gap-1')}
                >
                  {buttonItems?.map((bt, idx) => {
                    if (bt?.hidden) return null;

                    return (
                      <React.Fragment key={generateKey(idx)}>
                        {bt?.allowedRole && bt?.allowedRole.length > 0 ? (
                          <ComponentBlocker allowedRoles={bt.allowedRole} divId={bt?.divId}>
                            <CircleButton
                              iconName={bt?.iconName}
                              onClick={bt.action}
                              disabled={bt?.disabled || false}
                              hidden={bt?.hidden || false}
                              label={bt?.label}
                              tooltipText={bt?.tooltipText}
                              type={bt?.type}
                            />
                          </ComponentBlocker>
                        ) : (
                          <CircleButton
                            iconName={bt?.iconName}
                            onClick={bt.action}
                            disabled={bt?.disabled || false}
                            hidden={bt?.hidden || false}
                            label={bt?.label}
                            tooltipText={bt?.tooltipText}
                            type={bt?.type}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>
              )}
              <div className={styles.buttonsContainer}>
                {actionButtons.map((bt, idx) => {
                  if (bt?.hidden) return null;
                  return (
                    <React.Fragment key={generateKey(idx)}>
                      {bt?.allowedRole && bt?.allowedRole.length > 0 ? (
                        <ComponentBlocker allowedRoles={bt.allowedRole} divId={bt?.divId}>
                          <Button
                            size={getSizeButton(bt?.size)} //size={bt?.size || 'middle'}
                            key={bt?.label}
                            type={bt?.type}
                            danger={bt?.danger || false}
                            onClick={bt?.action}
                            disabled={bt?.disabled || false}
                            loading={bt?.loading || false}
                            hidden={bt?.hidden || false}
                            htmlType={bt?.htmlType}
                          >
                            {bt?.label}
                          </Button>
                        </ComponentBlocker>
                      ) : (
                        <Button
                          size={getSizeButton(bt?.size)}
                          key={bt?.label}
                          type={bt?.type}
                          danger={bt?.danger || false}
                          onClick={bt?.action}
                          disabled={bt?.disabled || false}
                          loading={bt?.loading || false}
                          hidden={bt?.hidden || false}
                          htmlType={bt?.htmlType}
                        >
                          {bt?.label}
                        </Button>
                      )}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
            {content && <div className={contentClass}>{content}</div>}
            {(showHide || description) && (
              <div className={styles.boxC}>
                {description ? <div>{description}</div> : <div />}
                {showHide && (
                  <Button
                    className="gx-mb-0 gx-pr-0"
                    onClick={() => setShow(prev => !prev)}
                    type="link"
                  >
                    <IntlMessages id={showHideButtonLabel} />
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

FilterContainer.defaultProps = {
  actionButtons: [],
  title: '',
  goBack: null,
  content: '',
  showHide: false,
  buttonItems: [],
};

FilterContainer.propTypes = {
  actionButtons: PropTypes.oneOfType([PropTypes.arrayOf(actionButton), PropTypes.any]),
  title: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
  goBack: PropTypes.func,
  content: PropTypes.oneOfType([PropTypes.string, ChildrenSchema]),
  showHide: PropTypes.bool,
  buttonItems: PropTypes.arrayOf(circleButtonPropType),
};

export default FilterContainer;
