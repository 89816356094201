import React from 'react';

import PropTypes from 'prop-types';
import L from 'leaflet';
import { Marker, Popup } from 'react-leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import styles from './styles.module.less';

/**
 * CurrentLocation component to display user current location on the map
 * @param {Object} position - Current user position
 * @param {String} icon - Icon to display
 * @returns {React.Component} - CurrentLocation component
 */
const CurrentLocation = ({ position = {}, icon = 'near_me', popupText = '' }) => {
  if (Object.keys(position).length === 0 || !position.lat || !position.lng) {
    return null;
  }

  const userLocationIcon = new L.DivIcon({
    html: renderToStaticMarkup(<span className="material-symbols-outlined">{icon}</span>),
    iconSize: [25, 41],
    className: styles.currentLocation,
  });

  return (
    <Marker position={{ lat: position?.lat, lng: position?.lng }} icon={userLocationIcon}>
      {popupText && <Popup>{popupText}</Popup>}
    </Marker>
  );
};

CurrentLocation.propTypes = {
  icon: PropTypes.string,
  popupText: PropTypes.string,
  position: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
};

export default React.memo(CurrentLocation);
