import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import IntlMessages from 'util/IntlMessages';

import Title from 'components/BoxContainer/components/Title';
import { InfoCardList } from 'components/InfoCard';
import MemberAvatar from 'components/MemberList/MemberAvatar';
import { Button } from 'antd';

const UsersSelected = ({ users, onRemoveUser, maxToShow = 2 }) => {
  const [seeAllUsers, setSeeAllUsers] = useState(false);
  const usersToShow = seeAllUsers ? users : users.slice(0, maxToShow);

  const buildAuxProps = usr => {
    const auxProps = {};

    if (onRemoveUser) {
      auxProps.actionButtons = [
        {
          iconButton: 'delete',
          action: () => onRemoveUser(usr.id),
          shape: 'circle',
        },
      ];
    }

    return auxProps;
  };

  useEffect(() => {
    if (users.length <= maxToShow) {
      setSeeAllUsers(false);
    }
  }, [users]);

  return (
    <div className="gx-flex-column">
      <div className="gx-guarnic-pb-1 gx-flex-row gx-justify-content-between">
        <Title.LabelForm value={<IntlMessages id="schedule.form.members.modal.users.title" />} />
        <div className="gx-flex-row gx-guarnic-gap-1">
          <span className="material-symbols-outlined">group</span>
          <span className="gx-align-self-center">{users.length}</span>
        </div>
      </div>
      {users.length === 0 ? (
        <span>
          <IntlMessages id="schedule.form.members.modal.users.noUsers" />
        </span>
      ) : (
        usersToShow.map(user => (
          <InfoCardList
            key={user.id}
            avatar={<MemberAvatar user={user} />}
            title={`${user?.firstName} ${user?.lastName}`}
            {...buildAuxProps(user)}
          />
        ))
      )}
      {!seeAllUsers && users.length > maxToShow && (
        <Button onClick={() => setSeeAllUsers(true)} type="link">
          <IntlMessages
            id="schedule.form.members.modal.groups.seeMore"
            values={{ amount: users.slice(maxToShow).length }}
          />
        </Button>
      )}
    </div>
  );
};

UsersSelected.defaultProps = {
  onRemoveUser: null,
  maxToShow: 2,
};

UsersSelected.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      photoURL: PropTypes.string,
      displayName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onRemoveUser: PropTypes.func,
  maxToShow: PropTypes.number,
};

export default UsersSelected;
