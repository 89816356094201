import React from 'react';
import { Layout } from 'antd';

import App from 'routes/index';

import Sidebar from '../Sidebar/index';
import Topbar from '../Topbar/index';

const MainApp = () => (
  <Layout className="gx-app-layout">
    <Sidebar />
    <Layout>
      <Topbar />
      <App />
    </Layout>
  </Layout>
);

export default React.memo(MainApp);
