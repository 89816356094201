import React from 'react';
import styles from './styles.module.less';
import MemberAvatar from './MemberAvatar';
import classNames from 'classnames';

export default function MemberUser({ displayName, user, className, ...props }) {
  return (
    <div {...props} className={classNames(styles.displayName, className)}>
      <div>
        <MemberAvatar user={user} />
      </div>
      <div className="gx-align-self-center gx-ml-2">{displayName}</div>
    </div>
  );
}
