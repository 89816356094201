import React from 'react';
import { Avatar, Card, Divider, Spin, Tooltip } from 'antd';
import classnames from 'classnames';
import { useParams, useHistory } from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';
import { UserOutlined } from '@ant-design/icons';
import { db } from 'firebase/firebase';
import { useFirestoreQuery } from 'packages/utils';
import { doc } from 'firebase/firestore';

import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import BoxContainer from 'components/BoxContainer';
import EventDatetime from '../../components/EventDatetime';
import { componentValue } from '../../components/EventComponents';
import TriggerProgress from '../../components/TriggerProgress';

import styles from './index.module.less';

const EventDetail = () => {
  const { orgId, divId, eventId } = useParams();
  const history = useHistory();

  const eventRef = doc(db, 'organizations', orgId, 'divisions', divId, 'events', eventId);
  const { data: event, loading } = useFirestoreQuery(eventRef, [orgId, divId, eventId]);
  const { triggerId, execution, eventType, organization, division } = event || {};
  const hasSchedule = execution?.schedule;
  const { data: trigger, loading: triggerLoading } = useFirestoreQuery(
    execution &&
      organization &&
      division &&
      doc(
        db,
        'organizations',
        organization.id,
        'divisions',
        division.id,
        'executions',
        execution.id,
        'triggers',
        triggerId,
      ),
    [organization?.id, division?.id, execution?.id, triggerId],
  );

  const color =
    (!!eventType &&
      `rgba(${eventType.color.r}, ${eventType.color.g}, ${eventType.color.b}, ${eventType.color.a})`) ||
    '';

  const componentsOrdered =
    (eventType && eventType.components?.sort((a, b) => a.order - b.order)) || [];

  return (
    <BoxContainer>
      <BoxContainer content shadow fixed>
        <FilterContainer
          goBack={() => history.goBack()}
          title={<Title value={<IntlMessages id="timeline.event" />} />}
        />
      </BoxContainer>
      <BoxContainer content loading={loading}>
        {event && (
          <>
            <div className={classnames('gx-flex-row gx-align-items-center gx-mb-4', styles.mobile)}>
              <Avatar
                size={37}
                className="gx-mr-4 gx-flex-row gx-align-items-center gx-justify-content-center"
                style={{
                  backgroundColor: color,
                }}
                icon={<i className="material-icons">{eventType.icon}</i>}
              />
              <EventDatetime datetime={event.createdAt} className="gx-mr-3 gx-mb-0" />
            </div>
            <Card>
              <div className="gx-flex-column">
                <div className="gx-flex-row gx-align-items-center gx-mb-2">
                  <div className={classnames('gx-flex-row gx-align-items-center', styles.desktop)}>
                    <Avatar
                      size={37}
                      className="gx-mr-4 gx-flex-row gx-align-items-center gx-justify-content-center"
                      style={{
                        backgroundColor: color,
                      }}
                      icon={<i className="material-icons">{eventType.icon}</i>}
                    />
                    <EventDatetime datetime={event.createdAt} className="gx-mr-3 gx-mb-0" />
                  </div>
                  {event.division?.name && (
                    <div className="gx-flex-row gx-mr-3">
                      <span className={classnames('material-icons', styles.divisionIcon)}>
                        groups
                      </span>
                      <h4 className={classnames('gx-mb-0', styles.text)}>
                        <IntlMessages
                          id="timeline.event.division"
                          values={{ name: event.division.name }}
                        />
                      </h4>
                    </div>
                  )}
                  {hasSchedule && (
                    <span className={styles.schedule}>{execution.schedule?.name}</span>
                  )}
                </div>
                <h1 className={classnames('gx-mb-2', styles.title)}>{eventType.name}</h1>
                {triggerLoading && <Spin />}
                {trigger && <TriggerProgress trigger={trigger} />}
                <div className="gx-flex-row gx-align-items-center gx-mt-3">
                  <Tooltip title={`${event?.user?.firstName} ${event?.user?.lastName}`}>
                    <Avatar
                      icon={<UserOutlined />}
                      shape="circle"
                      className={classnames('gx-size-30 gx-mr-2', styles.avatar)}
                      src={event.user.photoURL}
                    />
                  </Tooltip>
                </div>
              </div>
            </Card>
            <Card>
              {componentsOrdered.map((component, idx) => {
                const notLast = componentsOrdered.length > idx + 1;
                return (
                  <div key={component.label.name}>
                    {componentValue(component, idx, styles.text, true)}
                    {notLast && <Divider />}
                  </div>
                );
              })}
            </Card>
          </>
        )}
      </BoxContainer>
    </BoxContainer>
  );
};

export default React.memo(EventDetail);
