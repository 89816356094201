export const parseUserLocations = (data = []) => {
  if (data.length === 0) return;

  const result = data[0];
  const output = [];
  Object.keys(result).forEach(k => {
    if (!result[k].positions) return;
    output.push({
      id: k,
      positions: result[k].positions,
    });
  });

  return output;
};
