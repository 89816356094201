import React from 'react';
import { Row, Col } from 'antd';
import { KeyValueWidget } from 'components/KeyValueWidget';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import DisplayDuration from 'components/DisplayDuration';
import { useSelector } from 'react-redux';
import { jobsPropType } from 'packages/utils/proptypes/schedule';
import { getDurationFromStartDate } from 'packages/utils/functions/schedule';
import { Recurrence } from './Recurrence';
import { getUserTimeZoneDate } from 'packages/utils';
import styles from './styles.module.less';

const ScheduleInfo = ({
  id = '',
  startDate = null,
  endDate = null,
  duration = 0,
  divisionName = '',
  status = '',
  jobs = [],
  schedule = {},
  totalEvents = {},
  layout = { xs: 12, xl: 4, xxl: 4 },
  wrap = false,
  recurrence = true,
  type,
}) => {
  const { locale = 'es' } = useSelector(({ settings }) => settings.locale);
  const profile = useSelector(({ user }) => user.profile);
  const { repetition, startAt, timeZone: scheduleTimeZone } = schedule || {};

  const dateWithTimeZone = getUserTimeZoneDate(
    startAt?.toMillis(),
    scheduleTimeZone,
    profile?.data?.timeZone,
  ).jsDate;

  const wrapping = wrap ? styles.nowWrap : '';
  const eventAuxClass = totalEvents?.onClick ? 'gx-pointer gx-text-underline gx-text-blue' : '';

  return (
    <Row key={id} gutter={[8, 8]}>
      {divisionName && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.division.label" />}
            value={divisionName}
          />
        </Col>
      )}
      {startDate && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.start.label" />}
            value={
              <IntlMessages
                id={`schedules.list.date.${locale}.value`}
                values={{ date: startDate }}
              />
            }
          />
        </Col>
      )}
      {endDate && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.end.label" />}
            value={
              <IntlMessages id={`schedules.list.date.${locale}.value`} values={{ date: endDate }} />
            }
          />
        </Col>
      )}
      {duration && dateWithTimeZone && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id={`schedules.list.time.${locale}.label`} />}
            value={
              <IntlMessages
                id={`schedules.list.time.${locale}.value`}
                values={{
                  startDate: dateWithTimeZone,
                  endDate: getDurationFromStartDate(dateWithTimeZone, duration),
                }}
              />
            }
          />
        </Col>
      )}
      {duration && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.duration.label" />}
            value={<DisplayDuration minutes={duration} />}
          />
        </Col>
      )}
      {jobs?.start?.execution?.nextExecution && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.nextAt" />}
            value={
              <IntlMessages
                id={`schedules.list.date.${locale}.value`}
                values={{ date: new Date(jobs.start.execution.nextExecution) }}
              />
            }
          />
        </Col>
      )}
      {status && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.status.label" />}
            value={status}
          />
        </Col>
      )}
      {startAt && repetition && recurrence && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.reucurrence.label" />}
            value={<Recurrence repetition={repetition} startAt={startAt} />}
          />
        </Col>
      )}
      {totalEvents?.value && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            label={<IntlMessages id="schedules.list.total.events" />}
            value={
              <span
                onClick={totalEvents?.onClick ? totalEvents.onClick : () => null}
                className={eventAuxClass}
              >
                {totalEvents?.value}
              </span>
            }
          />
        </Col>
      )}
      {type && (
        <Col className={wrapping} {...layout}>
          <KeyValueWidget
            className="gx-text-capitalize"
            label={<IntlMessages id="schedules.list.type.label" />}
            value={type}
          />
        </Col>
      )}
    </Row>
  );
};

ScheduleInfo.propTypes = {
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  divisionName: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  jobs: jobsPropType,
  schedule: PropTypes.object,
  totalEvents: PropTypes.shape({ value: PropTypes.number, onClick: PropTypes.func }),
  layout: PropTypes.object,
  wrap: PropTypes.bool,
  recurrence: PropTypes.bool,
};

export { ScheduleInfo };
