import { transform, camelCase, isArray, isObject } from 'lodash';

export const camelize = obj =>
  transform(obj, (acc, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

export const dateTypes = ['day', 'week', 'month', 'quarter', 'year'];

export const calculateScheduleProgress = schedule => {
  const expectedExecutions = schedule.triggersNested.expectedExecutions.value;
  const finishedExecutions = schedule.triggersNested.finishedExecutions.value;
  const finishedPercentage = schedule.scheduleFinishedPercentage.value || 0;
  const triggers = schedule.triggersNested.byTrigger.buckets;
  const status = finishedPercentage < 100 ? 'active' : 'completed';

  return { expectedExecutions, finishedExecutions, finishedPercentage, triggers, status };
};
