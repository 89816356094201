import React from 'react';
import { Row, Col, Input, Select } from 'antd';
import IntlMessages from 'util/IntlMessages';
import PropTypes from 'prop-types';
import { KeyValueWidget } from 'components/KeyValueWidget';
import { SELECT_PAGES_OPTIONS } from 'packages/dashboard/constants';
import { useIntl } from 'react-intl';
import {
  STATUS_PER_DEFAULT,
  USER_DISPLAY_PER_DEFAULT,
} from '../../screens/ActiveUserDashboard/constants';

const ActiveUserFilter = ({ onChangeFilter }) => {
  const intl = useIntl();
  const SELECT_STATUS_OPTIONS = [
    {
      label: <IntlMessages id="dashboards.active_user.status.actives" />,
      value: 'actives',
    },
    {
      label: <IntlMessages id="dashboards.active_user.status.inactive" />,
      value: 'inactive',
    },
  ];

  return (
    <Row justify="space-between" gutter={[8, 8]}>
      <Col xs={24} md={8} xl={8} xxl={8}>
        <KeyValueWidget
          labelClass="gx-guarnic-pb-1"
          label={<IntlMessages id="dashboards.active_user.filters" />}
          value={
            <Input.Search
              onChangeCapture={e => onChangeFilter('text', e.target.value)}
              className="gx-mb-0"
              placeholder={intl.formatMessage({ id: 'activeUserDashboard.text.placeholder' })}
            />
          }
        />
      </Col>
      <Col xs={24} md={8} xl={8} xxl={8}>
        <KeyValueWidget
          labelClass="gx-guarnic-pb-1"
          label={<IntlMessages id="dashboards.active_user.filter.show" />}
          value={
            <Select
              defaultValue={STATUS_PER_DEFAULT}
              onChange={value => onChangeFilter('status', value)}
              className="gx-w-100"
              options={SELECT_STATUS_OPTIONS}
            />
          }
        />
      </Col>
      <Col xs={24} md={8} xl={8} xxl={8}>
        <KeyValueWidget
          labelClass="gx-guarnic-pb-1"
          label={<IntlMessages id="dashboards.active_user.filter.userforDisplay" />}
          value={
            <Select
              defaultValue={USER_DISPLAY_PER_DEFAULT}
              onChange={value => onChangeFilter('userPerDisplay', value)}
              className="gx-w-100"
              options={SELECT_PAGES_OPTIONS}
            />
          }
        />
      </Col>
    </Row>
  );
};

ActiveUserFilter.propTypes = {
  onChangeFilter: PropTypes.func.isRequired,
};

export default ActiveUserFilter;
