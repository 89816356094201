import { Pagination } from 'antd';
import BoxContainer from 'components/BoxContainer';
import Title from 'components/BoxContainer/components/Title';
import FilterContainer from 'components/FilterContainer';
import WorkOrderInfo from 'components/WorkOrders/WorkOrderInfo';
import { SCHEDULE_TYPES } from 'constants/commons';
import { db } from 'firebase/firebase';
import { collection, orderBy, query, where } from 'firebase/firestore';
import {
  ALLOWED_ROLES,
  getDivsWithAccess,
  useFirestoreRealtimeBatchedPagination,
} from 'packages/utils';
import { calculateNewStatus, executionFactory } from 'packages/utils/functions/executions';
import { useTriggersByExecutionsRt } from 'packages/utils/hooks/useTriggersByExecutionsRt';
import { get, set } from 'packages/utils/storage';
import WorkOrderFilter from 'packages/workOrders/components/WorkOrderFilter';
import {
  getRouteToDetails,
  INIT_FILTERS,
  PAGE_SIZE,
  PATHS,
  STATUS_ALLOWED_TO_REFRESH,
} from 'packages/workOrders/constants';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';

const WorkOrderList = () => {
  const workOrdersRef = useRef();
  const navigate = useHistory();

  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);

  const orgId = useSelector(state => state.organizations.organization.id);
  const userOrgAccess = useSelector(({ user }) => user.access.data?.claims.org[orgId]);
  const userOrgDivisions = useSelector(({ divisions }) => divisions.assigned.data.divisions);
  const allowedDivs = getDivsWithAccess(
    ALLOWED_ROLES.ORGANIZATIONS.DIVISIONS.SCHEDULES.LIST,
    userOrgAccess,
    userOrgDivisions,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState(get('workOrdersFilters') || INIT_FILTERS);
  const [workOrderList, setWorkOrderList] = useState([]);
  const [workOrdersFull, setWorkOrderFull] = useState([]);

  const allowedDivsIds = allowedDivs.map(d => d.id);
  const allowedSelectedDivs = selectedDivsIds.filter(divId => allowedDivsIds.includes(divId));

  const queryByDivId = {};
  allowedSelectedDivs.forEach(divId => {
    queryByDivId[divId] = query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'executions'),
      orderBy('createdAt', 'desc'),
      where('schedule.type', '==', SCHEDULE_TYPES.task),
    );
  });

  const getDataFiltered = useCallback(
    d =>
      d
        .filter(item => {
          const workOrderName = (item?.schedule?.name || '').toLowerCase();
          return workOrderName.includes(filters.name.toLowerCase());
        })
        .filter(item => {
          const status = item?.status;
          return filters.status.includes(status);
        })
        .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
    [filters.name, filters.status],
  );

  const {
    data: workOrders,
    loading,
    next,
    gotNewData,
    error,
  } = useFirestoreRealtimeBatchedPagination(
    Object.values(queryByDivId),
    [selectedDivsIds],
    PAGE_SIZE,
  );

  const onRefresh = () => {
    if (gotNewData && !loading) {
      next();
    }
  };

  const start = (currentPage - 1) * PAGE_SIZE;
  const end = start + PAGE_SIZE;
  const output = useMemo(() => getDataFiltered(workOrdersFull), [getDataFiltered, workOrdersFull]);
  const paginatedOutput = useMemo(() => output.slice(start, end) || [], [output, start, end]);
  const refreshByStatus = paginatedOutput.some(record =>
    STATUS_ALLOWED_TO_REFRESH.includes(record.status),
  );
  const totalPages = Math.ceil(output?.length + 1 / PAGE_SIZE);

  const { data: triggersListener, cleanupActiveSubscriptions } =
    useTriggersByExecutionsRt(paginatedOutput);

  const handlePageChange = page => {
    cleanupActiveSubscriptions();
    setCurrentPage(page);
    onRefresh();
    workOrdersRef?.current?.scrollIntoView();
  };

  const onShowMoreByTask = (divisionId, orderId) => {
    navigate.push(getRouteToDetails(divisionId, orderId));
  };

  // NOTE: To control the refresh of the list
  useEffect(() => {
    const fetchData = async () => {
      if (JSON.stringify(workOrders) !== JSON.stringify(workOrderList)) {
        setWorkOrderList(workOrders);
      }
    };

    fetchData();
  }, [workOrderList, workOrders]);

  // NOTE: This must be removed when the backend returns status
  useEffect(() => {
    const recalculateData = async () => {
      const resultado = await executionFactory(workOrderList);
      setWorkOrderFull(resultado);
    };

    if (!loading) recalculateData();
  }, [loading, refreshByStatus, workOrderList]);

  // NOTE: This must be removed when the backend returns status
  useEffect(() => {
    const updateWorkOrderStatus = () => {
      const dataUpdated = calculateNewStatus(workOrdersFull, triggersListener);

      const hasStatusChanged =
        dataUpdated.some(updatedItem => {
          const originalItem = workOrdersFull.find(item => item.id === updatedItem.id);
          const hasSomeTriggerChanged = originalItem.triggers.some(trigger => {
            const updatedTrigger = updatedItem.triggers.find(tr => tr.id === trigger.id);
            return (
              trigger?.expectedExecutions !== updatedTrigger?.expectedExecutions ||
              trigger?.finishedExecutions !== updatedTrigger?.finishedExecutions
            );
          });

          // NOTE: Check if status or some executions changed
          return (
            (originalItem && originalItem.status !== updatedItem.status) || hasSomeTriggerChanged
          );
        }) || false;

      if (hasStatusChanged) {
        setWorkOrderFull(dataUpdated);
      }
    };
    updateWorkOrderStatus(); // Call the function immediately
    const interval = setInterval(updateWorkOrderStatus, 30000); // Call the function every minute

    return () => {
      clearInterval(interval); // Clean up the interval when the component is unmounted
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggersListener]);

  // NOTE: Save filters in local storage
  useEffect(() => {
    set('workOrdersFilters', filters);
  }, [filters]);

  return (
    <BoxContainer>
      <BoxContainer fixed content shadow>
        <FilterContainer
          showHide
          title={<Title.Header value={<IntlMessages id="work.order.actives.title" />} />}
          content={
            <WorkOrderFilter handleFilters={setFilters} filters={filters} onRefresh={onRefresh} />
          }
          actionButtons={[
            {
              label: <IntlMessages id="button.create" />,
              action: () => navigate.push(PATHS.CREATE_ORDER),
              type: 'primary',
            },
          ]}
        />
      </BoxContainer>
      <BoxContainer
        content
        loading={loading}
        error={error}
        empty={!error && paginatedOutput?.length === 0}
      >
        {paginatedOutput.map((workOrder, idx) => (
          <React.Fragment key={workOrder.id}>
            <div ref={idx === 0 ? workOrdersRef : null}>
              <WorkOrderInfo
                onShowMore={() => onShowMoreByTask(workOrder?.divId, workOrder?.id)}
                key={workOrder?.id}
                workOrder={workOrder}
              />
            </div>
          </React.Fragment>
        ))}
      </BoxContainer>
      <div className="gx-flex-row gx-justify-content-center gx-mt-4">
        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          pageSize={PAGE_SIZE}
          total={totalPages}
        />
      </div>
    </BoxContainer>
  );
};

export default WorkOrderList;
