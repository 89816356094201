import React, { useMemo } from 'react';
import { Drawer } from 'antd';
import BoxContainer from 'components/BoxContainer';
import FilterContainer from 'components/FilterContainer';
import Title from 'components/BoxContainer/components/Title';
import EventTypeList from 'components/Events/EventTypeList';
import { PropTypes } from 'prop-types';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import {
  getRouteToDashboardSchedules,
  getRouteToTimelineEventDrawer,
} from 'packages/dashboard/constants';
import { camelize, getFullUrl, useFirestoreQuery } from 'packages/utils';
import { auth, db } from 'firebase/firebase';
import useGetParams from 'packages/utils/hooks/useGetParams';
import { CLOUD_FUNCTIONS } from 'util/cloud-functions/constants';
import useCloudFunction from 'packages/utils/hooks/clouds/useCloudFunction';
import { collection, query, where } from 'firebase/firestore';
import { calculateScheduleProgress } from 'packages/dashboard/screens/SchedulesDashboard/constants';

export default function EventTypesDrawer({ isVisible = false }) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const q = useGetParams();
  const { orgId } = useParams();

  const divId = q.get('divId');
  const gte = q.get('gte');
  const lte = q.get('lte');
  const interval = q.get('interval');
  const scheduleId = q.get('scheduleId').replace('/event_types', '');

  const paramsSent = {
    organizationId: orgId,
    divisionIds: [divId],
    uid: auth.currentUser.uid,
    range: {
      gte,
      lte,
    },
    interval,
    scheduleIds: [scheduleId],
  };

  const {
    data: scheduleFetched,
    loading,
    error,
  } = useCloudFunction({
    params: paramsSent,
    dependencies: [isVisible],
    functionName: CLOUD_FUNCTIONS.SCHEDULE_DASHBOARD,
    parseData: result => {
      const output = camelize(result);
      return output.aggregations.timeRange.histogram.buckets;
    },
  });

  const divEventTypesRef = query(
    collection(db, 'organizations', orgId, 'divisions', divId, 'schedules'),
    where('id', '==', scheduleId),
  );
  const { data: scheduleData = [], loading: scheduleLoading } = useFirestoreQuery(
    divEventTypesRef,
    [orgId, divId, scheduleId],
  );

  const scheduleNameTitle = scheduleData?.[0]?.name || '';

  const triggersById = useMemo(
    () =>
      scheduleData?.[0]?.triggers?.reduce((prevValue, trigger) => {
        const copy = { ...prevValue };

        copy[trigger.id] = {
          trigger,
        };
        return copy;
      }, {}) || {},
    [scheduleData],
  );

  const eventTypeListData = useMemo(() => {
    if (Object.keys(triggersById).length === 0 || scheduleFetched.length === 0) return [];

    const schData = scheduleFetched?.[0]?.bySchedule?.buckets[0];
    const scheduleProgress = schData && calculateScheduleProgress(schData);

    return (
      !!scheduleProgress &&
      scheduleProgress?.triggers?.map(trigger => {
        const triggerBySch = triggersById[trigger.key];
        return {
          ...triggerBySch?.trigger,
          finishedExecutions: trigger.finishedTriggerExecutions?.value,
          expectedExecutions: trigger.expectedTriggerExecutions?.value,
          finishedPercentage: trigger.triggerFinishedPercentage?.value,
        };
      })
    );
  }, [scheduleFetched, triggersById]);

  const mainLoading = loading || scheduleLoading || eventTypeListData?.length === 0;

  const goToTimeline = trId => {
    const path = getFullUrl(
      getRouteToTimelineEventDrawer(
        orgId,
        divId,
        trId,
        scheduleId,
        paramsSent.range.gte,
        paramsSent.range.lte,
      ),
      url,
    );

    history.push(path, {
      shadow: true,
    });
  };

  const goBack = () => history.push(getFullUrl(getRouteToDashboardSchedules(), url));

  return (
    <Drawer footer={null} closable={false} open={isVisible}>
      <BoxContainer content shadow fixed>
        <FilterContainer title={<Title.Header value={scheduleNameTitle} />} goBack={goBack} />
      </BoxContainer>
      <BoxContainer content loading={mainLoading} error={error}>
        <EventTypeList.SimpleList
          data={eventTypeListData}
          infoCardType="primary"
          onGoToTimeline={triggerId => goToTimeline(triggerId)}
        />
      </BoxContainer>
    </Drawer>
  );
}

EventTypesDrawer.propTypes = {
  isVisible: PropTypes.bool,
};
