import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import IntlMessages from 'util/IntlMessages';

import Title from 'components/BoxContainer/components/Title';
import { InfoCardList } from 'components/InfoCard';
import MemberAvatar from 'components/MemberList/MemberAvatar';
import { Button } from 'antd';

const GroupsSelected = ({ className, groups, onRemoveGroup, maxToShow = 2 }) => {
  const [seeAllGroups, setSeeAllGroups] = useState(false);
  const groupsToShow = seeAllGroups ? groups : groups.slice(0, maxToShow);

  const buildAuxProps = grp => {
    const auxProps = {};

    if (onRemoveGroup) {
      auxProps.actionButtons = [
        {
          iconButton: 'delete',
          action: () => onRemoveGroup(grp.id),
          shape: 'circle',
        },
      ];
    }

    return auxProps;
  };

  useEffect(() => {
    if (groups.length <= maxToShow) {
      setSeeAllGroups(false);
    }
  }, [groups]);
  return (
    <div className={classnames(className, 'gx-flex-column')}>
      <div className="gx-guarnic-pb-1 gx-flex-row gx-justify-content-between">
        <Title.LabelForm value={<IntlMessages id="schedule.form.members.modal.groups.title" />} />
        <div className="gx-flex-row gx-guarnic-gap-1">
          <span className="material-symbols-outlined">groups</span>
          <span className="gx-align-self-center">{groups.length}</span>
        </div>
      </div>
      {groups.length === 0 ? (
        <span>
          <IntlMessages id="schedule.form.members.modal.groups.noGroups" />
        </span>
      ) : (
        groupsToShow.map(group => (
          <InfoCardList
            key={group.id}
            avatar={<MemberAvatar user={group} />}
            title={group.name}
            {...buildAuxProps(group)}
          />
        ))
      )}

      {!seeAllGroups && groups.length > maxToShow && (
        <Button onClick={() => setSeeAllGroups(true)} type="link">
          <IntlMessages
            id="schedule.form.members.modal.groups.seeMore"
            values={{ amount: groups.slice(maxToShow).length }}
          />
        </Button>
      )}
    </div>
  );
};

GroupsSelected.defaultProps = {
  className: '',
  maxToShow: 2,
};

GroupsSelected.propTypes = {
  className: PropTypes.string,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onRemoveGroup: PropTypes.func,
  maxToShow: PropTypes.number,
};

export default React.memo(GroupsSelected);
